import { useEffect } from "react"
import { useResolvedPath, useMatch } from "react-router-dom"
import useUtils from '../../../hooks/useUtils'
import * as C from "../styles"

export default function NavButton({children, parentRoute, to, submenuItem='false', test=false}) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    const {setActiveRoute} = useUtils()
    const envsToHideMenu = ["production", "staging"]
    const hideMenu = envsToHideMenu.includes(process.env.REACT_APP_STAGE) ? true : false
    
    useEffect(() => {
        if (isActive) setActiveRoute(parentRoute)
    }, [isActive, setActiveRoute, parentRoute])

    if (hideMenu && test) return

    return (
        <C.NavMenuLink submenuitem={submenuItem} to={to} hasactive={isActive ? "true" : "false"} onClick={() => setActiveRoute(parentRoute)}>
            {children}
        </C.NavMenuLink>
    )
    
}