import React, { useState } from 'react';
import axios from 'axios';
import { PageCard, CardTitle, CardContainer } from '../../../styles/globals';
import * as C from '../styles';
import useData from '../../../hooks/useData';
import Table from '../../../components/DraggableTable/Table';
import { FaCheck } from 'react-icons/fa';
import { FormButton, Label } from '../../../styles/globals';
import { toast } from 'react-toastify';
import { getError } from '../../../contexts/errors';
import useProperties from '../../../hooks/useProperties';
import BeatLoader from 'react-spinners/BeatLoader';

export default function DefiningRoutes() {
  const { idPipeRoutes } = useProperties();
  const {
    sectionDictPipeRoutes,
    varListPipeRoutes,
    setVarListPipeRoutes,
    setElevation,
    setLeftDataInTime,
    selectedLeftVariable,
    setSelectedLeftVariable,
    setGridPipeRoutes,
    setTimePipeRoutes,
    setLeftUnit,
    setIsPlottingRoutes,
    isPlottingRoutes,
    setIsPipeRoutesChecked,
    isPipeRoutesChecked,
    isLoadingPipeRoutes,
  } = useData();

  const [isLoadingVariable, setIsLoadingVariable] = useState(0);
  const [unitListPipeRoutes, setUnitListPipeRoutes] = useState([]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'First Name',
            accessor: 'firstName',
          },
        ],
      },
    ],
    []
  );

  const [vars, setVars] = useState([]);
  const addOrRemoveVar = (item) => {
    const newVars = [...vars];
    const index = newVars.indexOf(item);
    if (index === -1) {
      newVars.push(item);
    } else {
      newVars.splice(index, 1);
    }
    setVars(newVars);
  };

  const handleRoutesPipes = () => {
    setIsPlottingRoutes(1);
    const output = [];
    vars.map((i) =>
      output.push('&file_names=profiles_' + i.firstName + '.csv')
    );
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/pipe-routes/retrieve-pipe-routes/?id=` +
          idPipeRoutes +
          output.join('') +
          `&left_variable=` +
          selectedLeftVariable,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        setIsPlottingRoutes(2);
        setGridPipeRoutes(res.data.grid_array);
        setTimePipeRoutes(res.data.time_array);
        setElevation(res.data.elevation_array);
        setLeftDataInTime(res.data.left_data_array_in_t);
      })
      .catch((e) => {
        toast.error(getError(e));
        setIsPlottingRoutes(0);
      });
    const output2 = [];
    vars.map((i) => output2.push('&id_pipe=' + i.id));
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/pipe-routes/check-pipe-routes/?id=` +
          idPipeRoutes +
          output2.join(''),
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        setIsPipeRoutesChecked(res.data.pipe_routes_flag);
        res.data.pipe_routes_flag === 1
          ? toast.success('Pipe routes were successfully defined.')
          : toast.warn('Pipe routes were not properly defined. Try again.');
      })
      .catch((e) => {
        toast.error(getError(e));
      });
  };

  const handleChangeLeftVariable = (e) => {
    setSelectedLeftVariable(e.target.value);
    setLeftUnit(
      unitListPipeRoutes[
        varListPipeRoutes.findIndex((element) => element === e.target.value)
      ]
    );
    setIsPlottingRoutes(0);
    setLeftDataInTime([]);
  };

  const handleVariableLoading = () => {
    if (varListPipeRoutes.length === 0) {
      setIsLoadingVariable(1);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/visualizer/retrieve-var-list/?id=` +
            idPipeRoutes +
            `&type_plot=profile&file_name=profiles_` +
            sectionDictPipeRoutes[0]['firstName'] +
            '.csv',
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('access')}`,
            },
          }
        )
        .then((res) => {
          setVarListPipeRoutes(res.data.var_list);
          setUnitListPipeRoutes(res.data.unit_list);
          setIsLoadingVariable(2);
        })
        .catch((e) => {
          toast.error(getError(e));
          setIsLoadingVariable(0);
        });
    } else {
      return;
    }
  };

  return (
    <>
      <PageCard rowposition={2} rowsize={2} colsize={4} colposition={4}>
        <C.TopCardContent>
          <CardTitle>Defining routes</CardTitle>
        </C.TopCardContent>
        <CardContainer>
          <C.CustomForm>
            <C.InputGroup align="col" className="gap-12">
              <C.InputGroup align="row" className="gap-12">
                <>
                  {sectionDictPipeRoutes.length === 0 ? (
                    <>No sections found</>
                  ) : (
                    <>
                      <C.Table className="h-96 w-64">
                        <C.TableHead>
                          <C.TableRowHead>
                            <C.TableHeadItem className="w-32">
                              Select sections
                            </C.TableHeadItem>
                            <C.TableHeadItem className="w-8">
                              <FaCheck />
                            </C.TableHeadItem>
                          </C.TableRowHead>
                        </C.TableHead>
                        <C.TableBody>
                          {sectionDictPipeRoutes.map((item, idx) => (
                            <C.TableRow key={idx}>
                              <C.TableItem className="w-32">
                                {item.firstName}
                              </C.TableItem>
                              <C.TableItem className="w-8">
                                <input
                                  type="checkbox"
                                  onChange={() => addOrRemoveVar(item)}
                                  value={item}
                                />
                              </C.TableItem>
                            </C.TableRow>
                          ))}
                        </C.TableBody>
                      </C.Table>
                      <Table columns={columns} data={vars} setData={setVars} />
                    </>
                  )}
                </>
              </C.InputGroup>

              <C.InputGroup align="row" className="gap-12">
                <C.InputGroup align="row">
                  <Label>Variable &nbsp; </Label>
                  {isLoadingVariable === 1 ? (
                    <BeatLoader className="w-64" color="gray" size={10} />
                  ) : (
                    <>
                      <C.Select
                        onClick={handleVariableLoading}
                        onChange={handleChangeLeftVariable}
                        value={selectedLeftVariable}
                        disabled={isLoadingPipeRoutes !== 2}
                      >
                        <option>Select a variable</option>
                        {varListPipeRoutes.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </C.Select>
                    </>
                  )}
                </C.InputGroup>

                <FormButton
                  color={isLoadingPipeRoutes !== 2 ? 'unable' : ''}
                  onClick={handleRoutesPipes}
                  disabled={isLoadingPipeRoutes !== 2}
                >
                  {isPlottingRoutes === 0 || isPipeRoutesChecked === 0
                    ? 'Plot'
                    : isPlottingRoutes === 1
                    ? 'Plotting...'
                    : 'Ready'}
                </FormButton>
              </C.InputGroup>
            </C.InputGroup>
          </C.CustomForm>
        </CardContainer>
      </PageCard>
    </>
  );
}
