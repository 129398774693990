import * as C from '../styles';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useUtils from '../../../hooks/useUtils.js';
import useData from '../../../hooks/useData.js';
import useProperties from '../../../hooks/useProperties.js';
import Input from '../../../components/AuthInput';
import { FaUser, FaLock } from 'react-icons/fa';
import HaiLogo from '../../../assets/Logo_HAI.svg';
//import { Label, LabelContent, LabelLink } from '../../../styles/globals.js';
import { Label } from '../../../styles/globals.js';
//import { Link } from 'react-router-dom';
import { clearLocalStorage } from '../../../contexts/functions.js';

export default function Login() {
  const { login, status, setStatus } = useAuth();
  const { setIsNavVisible } = useUtils();
  const { setActivePlotList, setActivePlotLabel } = useData();
  const { setID } = useProperties();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [isFocus, setIsFocus] = useState({ email: 'no', password: 'no' });

  const { email, password } = formData;

  const handleFocus = (e) => {
    setIsFocus({ ...isFocus, [e.target.name]: 'yes' });
  };

  const handleBlur = (e) => {
    if (e.target.value === '')
      setIsFocus({ ...isFocus, [e.target.name]: 'no' });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setActivePlotList(['Plot 1']); //resets activePlotList
    setActivePlotLabel('Plot 1');
    setID('');
    setStatus({ ...status, loading: true });
    login(email, password);
    clearLocalStorage();
  };

  useEffect(() => {
    setIsNavVisible(false);
  }, [setIsNavVisible]);

  return (
    <C.Form onSubmit={(e) => onSubmit(e)}>
      <a href="https://hybridai.com.br/" target="_blank" rel="noreferrer">
        <C.LoginImage src={HaiLogo} />
      </a>

      <C.LoginTitle>Welcome</C.LoginTitle>
      <Label type={status.type}>{status.message}</Label>
      <C.InputContent focus={isFocus['email']} className="mt-0">
        <C.IconContent focus={isFocus['email']}>
          <FaUser />
        </C.IconContent>
        <C.InputBox>
          <C.InputLabel focus={isFocus['email']}>E-mail</C.InputLabel>
          <Input
            type="email"
            name="email"
            value={email}
            required={true}
            onChange={(e) => onChange(e)}
            onFocus={(e) => handleFocus(e)}
            onBlur={(e) => handleBlur(e)}
          />
        </C.InputBox>
      </C.InputContent>

      <C.InputContent focus={isFocus['password']} className="mt-1">
        <C.IconContent focus={isFocus['password']}>
          <FaLock />
        </C.IconContent>
        <C.InputBox>
          <C.InputLabel focus={isFocus['password']}>Password</C.InputLabel>
          <Input
            type="password"
            name="password"
            value={password}
            required={true}
            onChange={(e) => onChange(e)}
            onFocus={(e) => handleFocus(e)}
            onBlur={(e) => handleBlur(e)}
          />
        </C.InputBox>
      </C.InputContent>

      <C.Button type="submit">Login</C.Button>
      {/*      <LabelContent>
        <LabelLink
          type="auth"
          align="left"
          size="small"
          onClick={() => setStatus({ message: '', type: '' })}
        >
          <Link to="/signup">Don't have an account?</Link>
        </LabelLink>
        <LabelLink
          type="auth"
          align="right"
          size="small"
          onClick={() => setStatus({ message: '', type: '' })}
        >
          <Link to="/reset-password">Forgot your password?</Link>
        </LabelLink>
      </LabelContent>*/}
    </C.Form>
  );
}
