import React, { useEffect, createContext } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from '../../hooks/useAuth';
import { PageGrid } from '../../styles/globals';
import InputData from './InputData';
import Plot from './Plot';

export const DataContext = createContext(null);

export default function Visualizer() {
  const { loadUser } = useAuth();

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <>
      <PageGrid>
        <InputData />
        <Plot />
      </PageGrid>
    </>
  );
}
