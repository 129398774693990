import BeatLoader from 'react-spinners/BeatLoader';
import * as C from './styles';
import useAuth from '../../hooks/useAuth';

export default function Loading({ type = 'auth' }) {
  const { status } = useAuth();

  if (type === 'auth' && status.loading) {
    return (
      <C.LoadingScreen>
        <BeatLoader color="white" loading={status.loading} size={50} />
      </C.LoadingScreen>
    );
  } else return;
}
