import React from 'react';
import { PageCard, CardTitle, CardContainer } from '../../../styles/globals';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import * as C from '../styles';
import useProperties from '../../../hooks/useProperties';
import useData from '../../../hooks/useData';

export default function ShowingRoutes() {
  const { idPipeRoutes } = useProperties();
  const { isLoadingPipeRoutes } = useData();

  return (
    <>
      <PageCard rowposition={2} rowsize={2} colsize={3} colposition={1}>
        <C.TopCardContent>
          <CardTitle>Showing routes</CardTitle>
        </C.TopCardContent>
        <CardContainer>
          <TransformWrapper>
            <TransformComponent>
              {isLoadingPipeRoutes === 2 ? (
                <>
                  {' '}
                  <img
                    src={`../../../svg_images/${idPipeRoutes}.svg`}
                    alt="No found"
                  />
                </>
              ) : (
                <>Please load case</>
              )}
            </TransformComponent>
          </TransformWrapper>
        </CardContainer>
      </PageCard>
    </>
  );
}
