import React from 'react';
import { PageGrid } from '../../styles/globals';
import Plot from './Plot';
import InputData from './InputData';
import ShowingRoutes from './ShowingRoutes';
import DefiningRoutes from './DefineRoutes';

export default function PipeRoutes() {
  return (
    <>
      <PageGrid>
        <InputData />
        <ShowingRoutes />
        <DefiningRoutes />
        <Plot />
      </PageGrid>
    </>
  );
}
