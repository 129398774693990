import { z } from 'zod';

export const analysisParametersSchema = z.object({
  hydrateCriteria: z
    .string()
    .nonempty('This field is required.')
    .transform((value) => Number(value))
    .refine((value) => !isNaN(Number(value)), 'Invalid number.')
    .refine(
      (value) => (value >= 0) & (value <= 100),
      'Should be greater or equal than 0 and less than 100.'
    ),
});
