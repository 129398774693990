import React, { useState } from 'react';
import {
  PageCard,
  CardTitle,
  CardContainer,
  Label,
  Input,
  FormButton,
} from '../../../styles/globals';
import * as C from '../styles';
import axios from 'axios';
import useProperties from '../../../hooks/useProperties';
import useData from '../../../hooks/useData';
import { toast } from 'react-toastify';
import { getError } from '../../../contexts/errors';

export default function InputData() {
  const { idPipeRoutes, setIDPipeRoutes, setImagePath } = useProperties();

  const {
    setSectionDictPipeRoutes,
    setSelectedLeftVariable,
    setVarListPipeRoutes,
    setLeftDataInTime,
    setLeftData,
    setElevation,
    isLoadingPipeRoutes,
    setIsLoadingPipeRoutes,
  } = useData();

  const handleCaseID = (e) => {
    e.preventDefault();
    setSectionDictPipeRoutes([]);
    setIsLoadingPipeRoutes(1);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visualizer/retrieve-section-list/?id=` +
          idPipeRoutes +
          `&type_plot=profile`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        setSectionDictPipeRoutes(res.data.section_dict);
        setSelectedLeftVariable('Select a variable');
        setVarListPipeRoutes([]);
        setLeftDataInTime([]);
        setLeftData([]);
        setElevation([]);
        setIsLoadingPipeRoutes(2);
      })
      .catch((e) => {
        toast.error(getError(e));
        setIsLoadingPipeRoutes(0);
      });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visualizer/case-details/` +
          idPipeRoutes,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        setImagePath(res.data.case_image);
      })
      .catch((e) => {
        toast.error(getError(e));
      });
  };

  return (
    <>
      <PageCard rowposition={1} rowsize={1} colsize={7} colposition={1}>
        <C.TopCardContent>
          <CardTitle>Setting up case</CardTitle>
        </C.TopCardContent>
        <CardContainer>
          <C.Form onSubmit={handleCaseID}>
            <C.FormGroup>
              <Label>Case id:</Label>
              <Input
                margin="none"
                typeformat="id"
                placeholder="Obtain Case id from the Catalogue"
                value={idPipeRoutes}
                onChange={(e) => setIDPipeRoutes(e.target.value)}
              />
            </C.FormGroup>
            <FormButton type="submit">
              {isLoadingPipeRoutes === 0
                ? 'Load ID'
                : isLoadingPipeRoutes === 1
                ? 'Loading...'
                : 'Loaded ID'}
            </FormButton>
          </C.Form>
        </CardContainer>
      </PageCard>
    </>
  );
}
