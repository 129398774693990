import React from 'react';
import ReactECharts from 'echarts-for-react';

export default function ProfileAndTrends({ data, checkElev }) {
  const selectedPlot = data?.selectedPlot;
  const selectedTimeUnit = data?.selectedTimeUnit;
  const y1Title = data?.selectedLeftVariable;
  const y1Unit = data?.leftUnit;
  const y1Data = data?.leftData;
  const y2Title = data?.selectedRightVariable;
  const y2Unit = data?.rightUnit;
  const y2Data = data?.rightData;
  const y3Data = data?.elevation;
  const colors = ['blue', 'darkgreen', 'black'];

  const changeTimeScale = (array) => {
    var outArr = [];
    for (let index = 0; index < array.length; index++) {
      const elem0 = array[index][0] / (selectedTimeUnit === 'h' ? 3600.0 : 1.0);
      const elem1 = array[index][1];
      outArr.push([elem0, elem1]); //outArr.push([parseFloat(elem0.toFixed(2)), elem1]);
    }
    return outArr;
  };

  const option = {
    color: colors,
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      triggerOn: 'click',
    },
    dataZoom: [
      {
        show: true,
        type: 'inside',
        filterMode: 'none',
        xAxisIndex: [0],
        startValue: 'dataMin',
        endValue: 'dataMax',
      },
      {
        show: true,
        type: 'inside',
        filterMode: 'none',
        yAxisIndex: [0],
        startValue: 'dataMin',
        endValue: 'dataMax',
      },
    ],
    xAxis: {
      type: 'value',
      name:
        selectedPlot === 'profile'
          ? 'L[m]'
          : selectedTimeUnit === 's'
          ? 't[s]'
          : 't[h]',
      nameLocation: 'center',
      nameGap: 25,
    },
    yAxis: [
      {
        type: 'value',
        name: y1Title === 'Select a variable' ? '' : y1Title + y1Unit,
        nameLocation: 'center',
        nameGap: 55,
        axisLine: {
          show: y1Title === 'Select a variable' ? '' : true,
          lineStyle: {
            color: colors[0],
          },
        },
      },
      {
        type: 'value',
        name:
          y2Title === 'Select a variable'
            ? ''
            : selectedPlot === 'custom'
            ? ''
            : y2Title + y2Unit,
        nameLocation: 'center',
        nameGap: 55,
        offset: 0,
        axisLine: {
          show:
            y2Title === 'Select a variable' ? '' : selectedPlot !== 'custom',
          lineStyle: {
            color: colors[1],
          },
        },
      },
      {
        type: 'value',
        name: (selectedPlot === 'profile') & checkElev ? 'Elev.[m]' : '',
        align: 'center',
        offset: 85,
        axisLine: {
          show: (selectedPlot === 'profile') & checkElev,
          lineStyle: {
            color: colors[2],
          },
        },
        nameLocation: 'center',
      },
    ],
    series: [
      {
        name: y1Title + y1Unit,
        data:
          selectedPlot === 'trends' || selectedPlot === 'custom'
            ? changeTimeScale(y1Data)
            : y1Data,
        type: 'line',
        yAxisIndex: 0,
      },
      {
        name: y2Title + y2Unit,
        data:
          selectedPlot === 'trends' || selectedPlot === 'custom'
            ? changeTimeScale(y2Data)
            : y2Data,
        type: 'line',
        yAxisIndex: 1,
      },
      {
        name: 'Elev.[m]',
        data: checkElev ? y3Data : [],
        type: 'line',
        yAxisIndex: 2,
      },
    ],
  };

  return (
    <>
      <ReactECharts
        style={{ height: '600px', width: '1200px' }}
        option={option}
      />
    </>
  );
}
