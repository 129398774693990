import { createContext, useState } from 'react';
export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);
  const [leftDataInTime, setLeftDataInTime] = useState([]);
  const [rightDataInTime, setRightDataInTime] = useState([]);
  const [leftTitle, setLeftTitle] = useState();
  const [rightTitle, setRightTitle] = useState();
  const [leftUnit, setLeftUnit] = useState();
  const [rightUnit, setRightUnit] = useState();
  const [isLoading, setIsLoading] = useState(0);
  const [isPlotting, setIsPlotting] = useState(0);
  const [activePlotList, setActivePlotList] = useState(['Plot 1']);
  const [activePlotLabel, setActivePlotLabel] = useState('Plot 1');
  const [elevation, setElevation] = useState([]);
  const [sectionDictPipeRoutes, setSectionDictPipeRoutes] = useState([]);
  const [varListPipeRoutes, setVarListPipeRoutes] = useState([]);
  const [selectedLeftVariable, setSelectedLeftVariable] = useState('');
  const [gridPipeRoutes, setGridPipeRoutes] = useState([]);
  const [timePipeRoutes, setTimePipeRoutes] = useState([]);
  const [isPlottingRoutes, setIsPlottingRoutes] = useState(0);
  const [isPipeRoutesChecked, setIsPipeRoutesChecked] = useState(0);
  const [isLoadingPipeRoutes, setIsLoadingPipeRoutes] = useState(0);
  return (
    <DataContext.Provider
      value={{
        leftData,
        setLeftData,
        rightData,
        setRightData,
        leftDataInTime,
        setLeftDataInTime,
        rightDataInTime,
        setRightDataInTime,
        leftTitle,
        setLeftTitle,
        rightTitle,
        setRightTitle,
        leftUnit,
        setLeftUnit,
        rightUnit,
        setRightUnit,
        isLoading,
        setIsLoading,
        isPlotting,
        setIsPlotting,
        activePlotList,
        setActivePlotList,
        activePlotLabel,
        setActivePlotLabel,
        elevation,
        setElevation,
        sectionDictPipeRoutes,
        setSectionDictPipeRoutes,
        varListPipeRoutes,
        setVarListPipeRoutes,
        selectedLeftVariable,
        setSelectedLeftVariable,
        gridPipeRoutes,
        setGridPipeRoutes,
        timePipeRoutes,
        setTimePipeRoutes,
        isPlottingRoutes,
        setIsPlottingRoutes,
        isPipeRoutesChecked,
        setIsPipeRoutesChecked,
        isLoadingPipeRoutes,
        setIsLoadingPipeRoutes,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
