import React from 'react';
import ReactECharts from 'echarts-for-react';
import { PageCard, CardTitle, CardContainer } from '../../../styles/globals';
import * as C from '../styles';
import PlotTab from '../PlotTab';

ReactECharts.prototype.getEchartsInstance = function () {
  const _this = this;
  const e = _this.echarts; // Access the protected property.
  // Explicitly add width and height in opts.
  const opts = { width: '1200px', height: '600px', ..._this.props.opts };
  return (
    e.getInstanceByDom(_this.ele) || e.init(_this.ele, _this.props.theme, opts)
  );
};

export default function Plot() {
  return (
    <PageCard rowposition={4} rowsize={5} colsize={7} colposition={1}>
      <C.TopCardContent>
        <CardTitle>Plots</CardTitle>
      </C.TopCardContent>
      <CardContainer gap="small" className="items-start" direction="row">
        <PlotTab />
      </CardContainer>
    </PageCard>
  );
}
