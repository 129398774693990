import React, { useEffect, createContext } from 'react';
import useAuth from '../../hooks/useAuth';
import { PageGrid } from '../../styles/globals';
import Cases from './Cases';

export const DataContext = createContext(null);

export default function Catalogue() {
  const { loadUser } = useAuth();

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <>
      <PageGrid>
        <Cases />
      </PageGrid>
    </>
  );
}
