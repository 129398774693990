import NavMenu from '../../../components/NavMenu';
import {
  PageContainer,
  PageContent,
  Content,
  PageTitle,
  Label,
} from '../../../styles/globals.js';
import HeaderMenu from '../../../components/HeaderMenu';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { useEffect } from 'react';
import Loading from '../../../components/Loading';
import { ToastContainer } from 'react-toastify';

export default function ProtectedLayout() {
  const { isAuthenticated, checkAuthenticated, status } = useAuth();
  const location = useLocation();

  const formatString = (str) => {
    // Replace hyphens with spaces
    str = str.replace(/-/g, ' ');

    // Split the string into words
    let words = str.split(' ');

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    // Join the words back into a single string
    str = words.join(' ');

    return str;
  };

  const getTitleFromLocation = (loc) => {
    const path = loc.pathname;
    if (path === '/') return 'Test Page';

    const parts = path.split('/').filter((part) => part !== '');
    const lastPart = parts[parts.length - 1];
    return formatString(lastPart);
  };

  const pageTitle = getTitleFromLocation(location);

  useEffect(() => {
    checkAuthenticated();
    const interval = setInterval(() => {
      checkAuthenticated();
    }, 3660000); // Access Token Lifetime

    return () => clearInterval(interval);
  }, [checkAuthenticated, isAuthenticated]);

  if (status.loading || isAuthenticated === null) return <Loading />;

  if (isAuthenticated)
    return (
      <PageContainer>
        <NavMenu />
        <HeaderMenu />
        <Content>
          <PageContent>
            <PageTitle>
              <Label size="big">{pageTitle}</Label>
            </PageTitle>
            <Outlet />
            <ToastContainer position="bottom-right" limit={1} />
          </PageContent>
        </Content>
      </PageContainer>
    );

  return <Navigate to="/login" state={{ from: location }} replace />;
}
