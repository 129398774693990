import axios from 'axios';
import { useCallback } from 'react';
import { createContext, useState } from 'react';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [access, setAccess] = useState(localStorage.getItem('access'));
  const [refresh, setRefresh] = useState(localStorage.getItem('refresh'));
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState({
    message: '',
    type: '',
    loading: false,
  });

  const loadUser = useCallback(() => {
    if (localStorage.getItem('access')) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          setUser(response.data);
          setStatus({ message: '', type: 'success', loading: false });
        })
        .catch(() => {
          setUser(null);
          setStatus({
            message: 'Failed to load user',
            type: 'error',
            loading: false,
          });
        });
    } else {
      setUser(null);
      setStatus({
        message: 'Failed to load user',
        type: 'error',
        loading: false,
      });
    }
  }, []);

  const login = (email, password) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, {
        email: email,
        password: password,
      })
      .then((response) => {
        localStorage.setItem('refresh', response.data.refresh);
        localStorage.setItem('access', response.data.access);
      })
      .then(() => {
        setAccess(localStorage.getItem('access'));
        setRefresh(localStorage.getItem('refresh'));
        setIsAuthenticated(true);
        setStatus({ message: '', type: 'success', loading: false });
      })
      .catch(() => {
        if (!email | !password)
          setStatus({
            message: 'Fill all the fields',
            type: 'error',
            loading: false,
          });
        else
          setStatus({
            message: "This user doesn't exist or isn't authorized",
            type: 'error',
            loading: false,
          });
        logout();
      });
  };

  const logout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('user');
    localStorage.removeItem('MY_ACTIVE_ROUTE');
    localStorage.clear();
    setAccess(null);
    setRefresh(null);
    setIsAuthenticated(false);
    setUser(null);
  };

  const checkAuthenticated = useCallback(() => {
    setStatus((status) => ({ ...status, loading: true }));
    if (localStorage.getItem('access')) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/auth/jwt/verify/`,
          {
            token: localStorage.getItem('access'),
          },
          {
            headers: {
              Accept: 'application/json',
            },
          }
        )
        .then((response) => {
          if (response.data.code !== 'token_not_valid') {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }

          setStatus((status) => ({ ...status, loading: false }));
        })
        .catch(() => {
          setIsAuthenticated(false);
          setStatus((status) => ({ ...status, loading: false }));
        });
    } else {
      setIsAuthenticated(false);
      setStatus((status) => ({ ...status, loading: false }));
    }
  }, []);

  const resetPassword = (email) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, {
        email: email,
      })
      .then(() => {
        setStatus({
          message: 'Check your email for confirmation',
          type: 'success',
          loading: false,
        });
      })
      .catch(() => {
        setStatus({
          message: 'Something went wrong',
          type: 'error',
          loading: false,
        });
      });
  };

  const resetPasswordConfirm = (uid, token, new_password, re_new_password) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
        {
          uid: uid,
          token: token,
          new_password: new_password,
          re_new_password: re_new_password,
        }
      )
      .then(() => {
        setStatus({
          message: 'Your password was successfully changed',
          type: 'success',
          loading: false,
        });
      })
      .catch(() => {
        setStatus({
          message: 'Something went wrong',
          type: 'error',
          loading: false,
        });
      });
  };

  const signup = (
    first_name,
    last_name,
    email,
    company,
    sector,
    job_position,
    password,
    re_password
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/users/`, {
        first_name: first_name,
        last_name: last_name,
        email: email,
        company: company,
        sector: sector,
        job_position: job_position,
        password: password,
        re_password: re_password,
      })
      .then(() => {
        setIsAuthenticated(false);
        setStatus({
          message: 'Confirm your account in your email.',
          type: 'success',
          loading: false,
        });
      })
      .catch((error) => {
        if (
          !first_name |
          !last_name |
          !email |
          !company |
          !job_position |
          !password |
          !re_password
        )
          setStatus({
            message: 'Fill all the required fields',
            type: 'error',
            loading: false,
          });
        else if (
          error.response.data.email[0] ===
          'user account with this email already exists.'
        )
          setStatus({
            message: 'User account with this email already exists',
            type: 'error',
            loading: false,
          });
        else if (
          error.response.data.non_field_errors[0] ===
          "The two password fields didn't match."
        )
          setStatus({
            message: 'The two password fields have to match',
            type: 'error',
            loading: false,
          });
        else
          setStatus({
            message: 'Something went wrong',
            type: 'error',
            loading: false,
          });
        logout();
      });
  };

  const verify = (uid, token) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, {
        uid: uid,
        token: token,
      })
      .then(() => {
        setStatus({
          message:
            'Account verified. Wait for an administrator to give you access.',
          type: 'success',
          loading: false,
        });
      })
      .catch(() => {
        setStatus({
          message: 'Something went wrong',
          type: 'error',
          loading: false,
        });
        logout();
      });
  };

  return (
    <AuthContext.Provider
      value={{
        access,
        refresh,
        status,
        setStatus,
        login,
        loadUser,
        logout,
        checkAuthenticated,
        setIsAuthenticated,
        isAuthenticated,
        user,
        resetPassword,
        resetPasswordConfirm,
        signup,
        verify,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
