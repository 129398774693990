import tw from 'tailwind-styled-components';

export const LoadingScreen = tw.div`
    fixed 
    bg-black/40
    top-0 
    left-0 
    w-full 
    h-full 
    text-white
    flex 
    flex-col 
    justify-center 
    items-center
`;
