import tw from 'tailwind-styled-components';

export const TopCardContent = tw.div`
    flex 
    flex-nowrap 
    w-full 
    justify-between
`;

export const Container = tw.div`
    flex 
    flex-col 
    items-center 
    justify-center
`;

export const ComponentContent = tw.div`

    ${(props) =>
      props.$selected
        ? 'bg-emerald-700 hover:bg-emerald-900'
        : 'bg-emerald-500 hover:bg-emerald-600'}

    rounded-lg 
    text-white 
    font-bold 
    w-20
    h-16 
    flex 
    items-center 
    justify-center
    cursor-pointer
    duration-300
`;

export const Form = tw.form`
    flex
    flex-row
    flex-start
    gap-4
`;

export const CustomForm = tw.div`
    flex
    flex-row
    flex-start
    gap-4
`;

export const FormGroup = tw.div`
    grid
    grid-cols-4
    items-baseline
`;

export const InputGroup = tw.div`
${(props) =>
  (props.align === 'row' && 'flex-row') ||
  (props.align === 'col' && 'flex-col')}
    flex
    items-center
    justify-center
`;

export const Table = tw.table`
    flex
    flex-col
    w-full 
    shadow-xl
    mt-2
    max-h-96
`;

export const TableHead = tw.thead`
    bg-emerald-700 
    border-b-2 
    border-emerald-900 
    text-white
    flex-[0_0_auto]
    pr-2
`;

export const TableRowHead = tw.tr`
    w-full 
    table 
    table-fixed
`;

export const TableBody = tw.tbody`
    divide-y 
    divide-zinc-300
    flex-[1_1_auto]
    block
    overflow-y-scroll
    scrollbar-thin
    scrollbar-thumb-emerald-700 
    scrollbar-track-zinc-300
`;

export const TableItem = tw.td`
    p-2 
    whitespace-nowrap
`;

export const TableHeadItem = tw.th`
    p-2 
    font-semibold 
    tracking-wide 
    text-left
`;

export const TableRow = tw.tr`
    ${(props) => (props.bgcolor === 'zinc' && 'bg-zinc-200') || 'bg-white'}

    w-full
    table
    table-fixed
`;

export const ButtonsContainer = tw.div`
    flex 
    gap-3 
    mr-3
    h-20
`;

export const ButtonsContainerTable = tw.div`
    flex 
    gap-1 
    mr-3
`;

export const Label = tw.label`
    pl-2
`;
export const H2 = tw.h2`
    mb-5
`;

export const Button = tw.button`
    ${(props) =>
      (props.color === 'danger' && 'bg-red-600 hover:bg-red-800 text-white') ||
      (props.color === 'gray' && 'bg-gray-600 hover:bg-gray-800 text-white') ||
      (props.color === 'light' && 'bg-gray-100 hover:bg-gray-300 text-black') ||
      'bg-emerald-600 hover:bg-emerald-800 text-white'}
    py-2
    px-2
    ease-linear
    duration-100
    rounded
    cursor-pointer
    w-1/8
    shadow-lg
    mt-2
    h-1/2
`;

export const Select = tw.select`

${(props) => (props.mode === 'time' && 'w-12') || 'w-64'}
    bg-gray-50
    border
    border-gray-300
    text-gray-900
    text-sm
    rounded-lg
    focus:ring-blue-500
    focus:border-blue-500
    block 
    h-12
    mb-2
    text-center
`;
