import { createGlobalStyle } from 'styled-components';
import tw from 'tailwind-styled-components';
import QuicksandRegular from './fonts/Quicksand-Regular.ttf';
import QuicksandLight from './fonts/Quicksand-Light.ttf';
import QuicksandMedium from './fonts/Quicksand-Medium.ttf';
import QuicksandBold from './fonts/Quicksand-Bold.ttf';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    @font-face {
        font-family: "quicksand";
        src: url(${QuicksandRegular}) format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "quicksand";
        src: url(${QuicksandLight}) format("truetype");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "quicksand";
        src: url(${QuicksandMedium}) format("truetype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "quicksand";
        src: url(${QuicksandBold}) format("truetype");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    body {
        background-color: #f0f2f5;
        font-family: 'quicksand', Arial, Helvetica, sans-serif;
        overflow-x: hidden;
    }

    html::-webkit-scrollbar {
        width: 0.5rem;
    }

    html::-webkit-scrollbar-track {
        background: #262626;
    }

    html::-webkit-scrollbar-thumb {
        background: #047857;
    }


`;

export const Content = tw.div`
    h-full
    w-full
    pt-24
    pb-2
    px-10
    top-20
`;

export const PageTitle = tw.div`
    w-full 
    flex
`;

export const PageContent = tw.div`

    ${(props) => (props.direction === 'col' && 'flex-col gap-3') || 'flex-col'}

    items-center 
    justify-center 
    h-full
`;

const getGridConfiguration = (cols = null, rows = null) => {
  if (cols === null && rows === null) return 'grid-cols-6 grid-rows-8';
  return `grid-cols-${cols} grid-rows-${rows}`;
};

export const PageGrid = tw.div`

    ${(props) => {
      return getGridConfiguration(props.rows, props.cols);
    }}
    
    grid
    w-full
    h-full
    gap-3
    my-3
    pb-4
`;

const getRowConfiguration = (rowPosition = null, rowSize = null) => {
  if (rowPosition === null && rowSize === null) return 'row-[1_/_span_1]';
  return `row-span-${rowSize} row-start-${rowPosition}`;
};

const getColConfiguration = (colPosition = null, colSize = null) => {
  if (colPosition === null && colSize === null) return 'col-[1_/_span_1]';
  return `col-span-${colSize} col-start-${colPosition}`;
};

export const PageCard = tw.div`

    ${(props) => (props.direction === 'row' && 'flex-row') || 'flex-col'}

    ${(props) => (props.wrap === 'true' && 'flex-wrap') || 'flex-nowrap'}

    ${(props) => {
      return getRowConfiguration(props.rowposition, props.rowsize);
    }}

    ${(props) => {
      return getColConfiguration(props.colposition, props.colsize);
    }}

    flex
    items-center
    justify-between
    w-full
    bg-white
    rounded
    shadow-lg
    relative
    p-8
`;

export const CardContainer = tw.div`

    ${(props) => (props.direction === 'row' && 'flex-row') || 'flex-col'}
    flex
    items-center 
    justify-center 
    h-full 
    w-full
    ${(props) =>
      (props.gap === 'small' && 'gap-4') ||
      (props.gap === 'medium' && 'gap-10 items-start')}
`;

export const CardTitle = tw.h2`
    text-xl 
    mb-2 
    text-zinc-400 
    self-start
`;

export const FormButton = tw.button`
    ${(props) =>
      (props.color === 'danger' && 'bg-red-600 hover:bg-red-800 text-white') ||
      (props.color === 'gray' && 'bg-gray-600 hover:bg-gray-800 text-white') ||
      (props.color === 'light' && 'bg-gray-100 hover:bg-gray-300 text-black') ||
      (props.color === 'unable' &&
        'bg-gray-100 hover:cursor-not-allowed text-black') ||
      'bg-emerald-600 hover:bg-emerald-800 text-white'}

    ${(props) => (props.icon === 'true' && 'px-5') || 'px-0'}

    ${(props) => (props.circle === true && 'rounded-full') || 'rounded'}

    ${(props) => (props.fontweight === 'bold' && 'font-bold') || 'font-normal'}

    py-2
    ease-linear
    duration-100
    cursor-pointer
    h-12
    w-24
    shadow-lg
`;

export const Label = tw.div`
    ${(props) =>
      (props.type === 'error' && 'text-red-400') ||
      (props.type === 'success' && 'text-green-400') ||
      'text-zinc-600'}

    ${(props) =>
      (props.size === 'small' && 'text-sm') ||
      (props.size === 'big' && 'text-xl') ||
      (props.size === 'bigger' && 'text-4xl') ||
      'text-base'}

    font-semibold
    text-center
`;

export const LabelItem = tw.div`
    font-thin
`;

export const LabelLink = tw.div`
    ${(props) =>
      (props.type === 'error' && 'text-red-600 hover:text-red-900') ||
      (props.type === 'success' && 'text-green-600 hover:text-green-900') ||
      (props.type === 'auth' && 'text-emerald-600 hover:text-emerald-900') ||
      'text-gray-500 hover:text-gray-900'}

    ${(props) =>
      (props.size === 'small' && 'text-sm') ||
      (props.size === 'big' && 'text-xl') ||
      (props.size === 'bigger' && 'text-4xl') ||
      'text-base'}

    ${(props) =>
      (props.align === 'left' && 'self-start') ||
      (props.align === 'right' && 'self-end') ||
      ''}

    font-semibold
    block
    transition-all
    duration-300
    no-underline
`;

export const LabelContent = tw.div`
    flex
    justify-between
`;

export const PageContainer = tw.div`
    flex
    justify-center
    ml-14
    transition-all 
    duration-500
    w-[calc(100vw-3.5rem)]
    h-screen
    align-bottom
`;

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(4, 120, 87, 0.9)',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

export const Input = tw.input`
    ${(props) =>
      (props.mods && props.mods.focus === 'true' && 'border-zinc-900') ||
      'border-zinc-300'}

    ${(props) =>
      (props.margin === 'big' && 'mx-8') ||
      (props.margin === 'small' && 'mx-2') ||
      (props.margin === 'none' && 'mx-0') ||
      'mx-4'}

    ${(props) =>
      (props.typeformat === 'number' && 'w-20') ||
      (props.typeformat === 'id' && 'col-span-3 w-96') ||
      'px-4 w-full'}

    outline-none
    mb-2
    py-2
    text-center
    rounded
    border-2
    duration-300
    [&::-webkit-outer-spin-button]:appearance-none 
    [&::-webkit-inner-spin-button]:appearance-none
    [appearance:textfield]
`;

export default GlobalStyle;
