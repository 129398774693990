import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as C from '../styles';
import 'react-tabs/style/react-tabs.css';
import { AiOutlineClose } from 'react-icons/ai';
import ProfileAndTrends from '../ProfileAndTrends';
import useData from '../../../hooks/useData';
import PlotCard from '../PlotCard';
import { Label } from '../styles';
import { toast } from 'react-toastify';

export default function PlotTab() {
  const {
    activePlotList,
    setActivePlotList,
    activePlotLabel,
    setActivePlotLabel,
  } = useData();
  const [checkElev, setCheckElev] = useState(false);

  const handleAddAction = () => {
    if (activePlotList.length >= 10) {
      toast.warning(
        'Add a plot not available. Maximum number of plots reached.'
      );
      return;
    }
    activePlotList.length === 0
      ? setActivePlotList(['Plot 1'])
      : setActivePlotList([
          ...activePlotList,
          'Plot ' +
            String(Number(activePlotList.slice(-1)[0].substring(4)) + 1),
        ]);
  };

  const handleDeleteAction = (e, tabToDeleteIdx) => {
    e.stopPropagation();
    setActivePlotList(
      activePlotList.filter((item) => item !== activePlotList[tabToDeleteIdx])
    );
    localStorage.removeItem('tempData-' + activePlotList[tabToDeleteIdx]); // del localStorage item
    setActivePlotLabel('');
  };

  const handleActive = (e, index) => {
    e.stopPropagation();
    setActivePlotLabel(activePlotList[index]);
  };

  const handleOnCheckElev = () => {
    setCheckElev(!checkElev);
  };

  return (
    <Tabs
      defaultIndex={activePlotList.indexOf(activePlotLabel)}
      className="w-full"
    >
      <TabList>
        {activePlotList.map((item, index) => (
          <Tab key={index} onFocus={(e) => handleActive(e, index)}>
            {item} &nbsp;
            {JSON.parse(
              localStorage.getItem('tempData-' + activePlotList[index])
            )?.tags?.label_tags?.slice(1, -1)}
            &nbsp;&nbsp;
            <C.Button
              color="light"
              onClick={(e) => handleDeleteAction(e, index)}
            >
              <AiOutlineClose />
            </C.Button>
          </Tab>
        ))}
        <Tab onClick={handleAddAction}>Add a plot</Tab>
      </TabList>
      {activePlotList.map((item, index) => (
        <TabPanel key={index}>
          {JSON.parse(
            localStorage.getItem('tempData-' + activePlotList[index])
          ) === null ? (
            <></>
          ) : (
            <>
              {JSON.parse(
                localStorage.getItem('tempData-' + activePlotList[index])
              ).selectedPlot === 'profile' && (
                <>
                  <Label>Elev.: &nbsp;</Label>
                  <input
                    type="checkbox"
                    value={checkElev}
                    defaultChecked={checkElev}
                    onChange={(e) => handleOnCheckElev(e)}
                  />
                </>
              )}
              <div className="flex flex-row gap-x-20">
                <ProfileAndTrends
                  data={JSON.parse(
                    localStorage.getItem('tempData-' + activePlotList[index])
                  )}
                  checkElev={checkElev}
                />
                <PlotCard
                  data={JSON.parse(
                    localStorage.getItem('tempData-' + activePlotList[index])
                  )}
                />
              </div>
            </>
          )}
        </TabPanel>
      ))}
      <TabPanel>
        {activePlotList.length === 0
          ? 'Please Add a plot'
          : `Please return to ${activePlotList.slice(-1)}`}
      </TabPanel>
    </Tabs>
  );
}
