import tw from 'tailwind-styled-components';

export const Form = tw.form`
    md:w-[30rem]
    rounded-2xl
    bg-neutral-900
    opacity-90
    hover:opacity-100
    transition-all
    duration-500
    py-4
    px-6
    shadow-[0_0_60px_rgba(15,89,69,0.8)]
    hover:shadow-black
    w-72
`;

export const LoginImage = tw.img`
    sm:h-28
    inline
    mb-2
    h-24
    cursor-pointer
    hover:brightness-125
    transition-all
    duration-300
`;

export const LoginTitle = tw.h2`
    uppercase 
    lg:text-5xl
    text-4xl
    my-4
    mx-0
    text-zinc-200
`;

export const InputContent = tw.div`

    ${(props) =>
      (props.focus === 'yes' && 'before:w-1/2 after:w-1/2') ||
      'before:w-0 after:w-0'}

    relative
    grid
    grid-cols-[7%_93%]
    my-6
    mx-0
    py-1.5
    px-0
    border-b-2
    border-zinc-200

    after:content-[''] 
    after:absolute 
    after:h-0.5 
    after:bg-emerald-500
    after:duration-300
    after:-bottom-0.5
    after:right-2/4

    before:content-[''] 
    before:absolute
    before:h-0.5 
    before:bg-emerald-500
    before:duration-300
    before:-bottom-0.5
    before:left-2/4

`;

export const IconContent = tw.div`

    ${(props) =>
      (props.focus === 'yes' && 'text-emerald-500') || 'text-zinc-200'}

    flex 
    justify-center 
    items-center
    duration-300
`;

export const InputBox = tw.div`
    relative 
    h-12
`;

export const InputLabel = tw.h5`

    ${(props) =>
      (props.focus === 'yes' && 'text-sm -top-1') || 'text-lg top-2/4'}

    absolute 
    -translate-y-2/4 
    text-zinc-200
    duration-300
    left-2.5
    z-10
    pointer-events-none
`;

export const LabelLink = tw.div`
    ${(props) =>
      (props.type === 'error' && 'text-red-600 hover:text-red-900') ||
      (props.type === 'success' && 'text-green-600 hover:text-green-900') ||
      'text-gray-500 hover:text-gray-900'}

    ${(props) =>
      (props.size === 'small' && 'text-sm') ||
      (props.size === 'big' && 'text-xl') ||
      (props.size === 'bigger' && 'text-4xl') ||
      'text-base'}

    ${(props) =>
      (props.align === 'left' && 'text-left') ||
      (props.align === 'right' && 'text-right') ||
      'text-center'}

    font-semibold
    inline
    transition-all
    duration-300
    no-underline
    px-6
`;

export const IconPageContent = tw.div`
    flex 
    justify-center 
    gap-x-5 
    text-emerald-500
`;

export const SponsorContent = tw.div`
    flex 
    justify-center 
    items-center 
    mt-1
`;

export const SponsorLink = tw.a`
    flex 
    justify-center 
    items-center 
    gap-x-2
    hover:brightness-75
    transition-all
    duration-300
`;

export const SponsorText = tw.h2`
    text-sm 
    text-emerald-400
    font-semibold
`;

export const SponsorImage = tw.img`
    w-8
`;

export const Button = tw.button`

    ${(props) =>
      (props.margin === 'sm' && 'my-2') ||
      (props.margin === 'xl' && 'my-8') ||
      'my-4'}

    block 
    w-full 
    h-12
    bg-gradient-to-r
    from-emerald-900
    via-emerald-600
    to-emerald-900
    bg-[length:200%]
    text-xl
    text-white 
    uppercase 
    cursor-pointer 
    duration-500
    mx-0 
    rounded-3xl
    border-none
    hover:bg-right
`;
