import { createContext, useEffect, useState } from "react"

export const AuthContext = createContext({})

// {item: '',
// content: '',
// type: '',
// visibility: false}

export const UtilsProvider = ({children}) => {
    const [message, setMessage] = useState({})
    const [messageVisibility, setMessageVisibility] = useState(false)
    const [messageType, setMessageType] = useState('')
    const [isNavVisible, setIsNavVisible] = useState(false)
    const [isQuickMode, setIsQuickMode] = useState(true)

    const[activeRoute, setActiveRoute] = useState()

    useEffect(() => {
        const activeRouteData = window.localStorage.getItem('MY_ACTIVE_ROUTE')
        if (activeRouteData !== null && activeRouteData !== "undefined") {
            setActiveRoute(JSON.parse(activeRouteData))
        }
    }, [])

    useEffect(() => {
        window.localStorage.setItem('MY_ACTIVE_ROUTE', JSON.stringify(activeRoute))
    }, [activeRoute])

    const errorHandler = (customMessage, item) => {
            setMessage({...message, [item]: {content: customMessage, type: 'error', visibility: true}})
        }

    const successfulAction = (customMessage = '', item) => {
        if (customMessage === '') {
            setMessage({...message, [item]: {content: customMessage, visibility: false, type: 'success'}})
        } else {
            setMessage({...message, [item]: {content: customMessage, visibility: true, type: 'error'}})
        }
    }

    return (
        <AuthContext.Provider value={{message, setMessage, 
                                    messageVisibility, setMessageVisibility,
                                    messageType, setMessageType,
                                    errorHandler, successfulAction,
                                    activeRoute, setActiveRoute,
                                    isNavVisible, setIsNavVisible,
                                    isQuickMode, setIsQuickMode}}>
            {children}
        </AuthContext.Provider>
    )
}