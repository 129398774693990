import * as C from './styles';

export default function Input({
  type,
  placeholder = null,
  name = null,
  value,
  onChange,
  onFocus = null,
  onBlur = null,
  margin = null,
  required = false,
  autocomplete = null,
}) {
  if (type === 'number') {
    return (
      <C.NumberInput
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
        placeholder={placeholder}
        name={name}
        margin={margin}
        required={required}
        autoComplete={autocomplete}
      />
    );
  } else {
    return (
      <C.Input
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
        placeholder={placeholder}
        name={name}
        margin={margin}
        required={required}
        autoComplete={autocomplete}
      />
    );
  }
}
