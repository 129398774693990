export const sortToPlot = (inputArray1, inputArray2) => {
  let outputArray = [];
  try {
    for (let index = 0; index < inputArray1.length; index++) {
      outputArray.push([inputArray1[index], inputArray2[index]]);
    }
    return outputArray;
  } catch (error) {
    return [];
  }
};

export const firstCapital = (input) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const clearLocalStorage = () => {
  const accessKey = localStorage.getItem('access');
  const refreshKey = localStorage.getItem('refresh');
  const MY_ACTIVE_ROUTEKey = localStorage.getItem('MY_ACTIVE_ROUTE');
  localStorage.clear();
  localStorage.setItem('access', accessKey);
  localStorage.setItem('refresh', refreshKey);
  localStorage.setItem('MY_ACTIVE_ROUTE', MY_ACTIVE_ROUTEKey);
};
