import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PublicLayout from '../pages/Layouts/PublicLayout';
import ProtectedLayout from '../pages/Layouts/ProtectedLayout';
import Login from '../pages/Auth/Login';
import Signup from '../pages/Auth/Signup';
import Activate from '../pages/Auth/Activate';
import ResetPassword from '../pages/Auth/ResetPassword';
import ResetPasswordConfirm from '../pages/Auth/ResetPasswordConfirm';
import React from 'react';
import Visualizer from '../pages/Visualizer';
import Catalogue from '../pages/Catalogue';
import PipeRoutes from '../pages/PipeRoutes';

export default function RoutesApp() {
  return (
    <BrowserRouter>
      <>
        <Routes>
          {/* Public Routes */}
          <Route element={<PublicLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route path="/activate/:uid/:token" element={<Activate />} />
          </Route>
          {/* Protected Routes */}
          <Route element={<ProtectedLayout />}>
            <Route index element={<Navigate to="/catalogue" />} />
            <Route path="/catalogue" element={<Catalogue />}></Route>
            <Route index element={<Navigate to="/visualizer" />} />
            <Route path="/visualizer" element={<Visualizer />}></Route>
            <Route path="/pipe-routes" element={<PipeRoutes />}></Route>
          </Route>
        </Routes>
      </>
    </BrowserRouter>
  );
}
