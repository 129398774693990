import * as C from '../styles';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useUtils from '../../../hooks/useUtils.js';
import Input from '../../../components/AuthInput';
import { FaUser } from 'react-icons/fa';
import HaiLogo from '../../../assets/Logo_HAI.svg';
import { Label, LabelContent, LabelLink } from '../../../styles/globals.js';
import { Link } from 'react-router-dom';

export default function ResetPassword() {
  const { resetPassword, status, setStatus } = useAuth();
  const { setIsNavVisible } = useUtils();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '' });

  const { email } = formData;

  const [isFocus, setIsFocus] = useState({ email: 'no' });

  const handleFocus = (e) => {
    setIsFocus({ ...isFocus, [e.target.name]: 'yes' });
  };

  const handleBlur = (e) => {
    if (e.target.value === '')
      setIsFocus({ ...isFocus, [e.target.name]: 'no' });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    setStatus({ ...status, loading: true });

    resetPassword(email);
  };

  useEffect(() => {
    setIsNavVisible(false);
  }, [setIsNavVisible, setStatus]);

  useEffect(() => {
    if (status.type === 'success') {
      navigate('/');
    }
  }, [status, navigate]);

  return (
    <C.Form onSubmit={(e) => onSubmit(e)}>
      <a href="https://hybridai.com.br/" target="_blank" rel="noreferrer">
        <C.LoginImage src={HaiLogo} />
      </a>
      <C.LoginTitle>Forgot Your Password</C.LoginTitle>
      <Label type={status.type}>{status.message}</Label>

      {/* Email Input */}
      <C.InputContent focus={isFocus['email']} className="mt-1">
        <C.IconContent focus={isFocus['email']}>
          <FaUser />
        </C.IconContent>
        <C.InputBox>
          <C.InputLabel focus={isFocus['email']}>Email</C.InputLabel>
          <Input
            type="email"
            name="email"
            value={email}
            required={true}
            onChange={(e) => onChange(e)}
            onFocus={(e) => handleFocus(e)}
            onBlur={(e) => handleBlur(e)}
          />
        </C.InputBox>
      </C.InputContent>

      <C.Button type="submit">Send Link</C.Button>

      <LabelContent>
        <LabelLink
          type="auth"
          size="small"
          onClick={() => setStatus({ message: '', type: '', loading: false })}
        >
          <Link to="/login">Go back</Link>
        </LabelLink>
      </LabelContent>
    </C.Form>
  );
}
