import tw from 'tailwind-styled-components';

export const LoginContent = tw.div`
    flex 
    justify-start 
    items-center 
    text-center
`;

export const Container = tw.div`
    w-screen 
    h-screen 
    flex
    lg:justify-between
    justify-center
    items-center
    py-0
    lg:px-32
    xl:px-24
`;

export const MainText = tw.div`
    font-semibold
    text-white
    xl:text-7xl
    lg:text-5xl
    lg:block
    hidden
`;

export const BackgroundImage = tw.img`
    fixed
    w-full 
    h-full
    -z-20
    object-cover
    brightness-50
    blur-sm
    scale-105
`;
