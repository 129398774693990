import React from 'react';
import ReactECharts from 'echarts-for-react';

export default function PlotPipeRoutes({
  selectedLeftVariable,
  leftUnit,
  leftData,
  elevation,
}) {
  const y1Title = selectedLeftVariable;
  const y1Unit = leftUnit;
  const y1Data = leftData;
  const y3Data = elevation;
  const colors = ['blue', 'black'];
  const option = {
    color: colors,
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      triggerOn: 'click',
    },
    dataZoom: [
      {
        show: true,
        type: 'inside',
        filterMode: 'none',
        xAxisIndex: [0],
        startValue: 'dataMin',
        endValue: 'dataMax',
      },
    ],
    xAxis: {
      type: 'value',
      name: 'L[m]',
      nameLocation: 'center',
      nameGap: 25,
    },
    yAxis: [
      {
        type: 'value',
        name: y1Title + y1Unit,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[0],
          },
        },
      },
      {
        type: 'value',
        name: 'Elev.[m]',
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[1],
          },
        },
      },
    ],
    series: [
      {
        name: y1Title + y1Unit,
        data: y1Data,
        type: 'line',
        yAxisIndex: 0,
      },
      {
        name: 'Elev.[m]',
        data: y3Data,
        type: 'line',
        yAxisIndex: 1,
      },
    ],
  };

  return (
    <>
      <ReactECharts
        style={{ height: '600px', width: '1200px' }}
        option={option}
      />
    </>
  );
}
