import * as C from '../styles';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useUtils from '../../../hooks/useUtils.js';
import Input from '../../../components/AuthInput';
import { FaUser, FaLock } from 'react-icons/fa';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import HaiLogo from '../../../assets/Logo_HAI.svg';
import { Label, LabelContent, LabelLink } from '../../../styles/globals.js';
import { Link } from 'react-router-dom';

export default function Signup() {
  const { signup, status, setStatus } = useAuth();
  const { setIsNavVisible } = useUtils();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    sector: '',
    job_position: '',
    password: '',
    re_password: '',
  });
  const {
    first_name,
    last_name,
    email,
    company,
    sector,
    job_position,
    password,
    re_password,
  } = formData;

  const [page, setPage] = useState(1);

  const [isFocus, setIsFocus] = useState({
    first_name: 'no',
    last_name: 'no',
    email: 'no',
    company: 'no',
    sector: 'no',
    job_position: 'no',
    password: 'no',
    re_password: 'no',
  });

  const handleFocus = (e) => {
    setIsFocus({ ...isFocus, [e.target.name]: 'yes' });
  };

  const handleBlur = (e) => {
    if (e.target.value === '')
      setIsFocus({ ...isFocus, [e.target.name]: 'no' });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    if (password === re_password) {
      setStatus({ ...status, loading: true });
      signup(
        first_name,
        last_name,
        email,
        company,
        sector,
        job_position,
        password,
        re_password
      );
    } else {
      setStatus({
        message: 'The two password fields have to match',
        type: 'error',
        loading: false,
      });
    }
  };

  useEffect(() => {
    setIsNavVisible(false);
  }, [setIsNavVisible, setStatus]);

  useEffect(() => {
    if (status.type === 'success') {
      navigate('/login');
    }
  }, [status, navigate]);

  return (
    <C.Form onSubmit={(e) => onSubmit(e)}>
      <a href="https://hybridai.com.br/" target="_blank" rel="noreferrer">
        <C.LoginImage src={HaiLogo} />
      </a>
      <C.LoginTitle>Signup</C.LoginTitle>
      <Label type={status.type}>{status.message}</Label>

      {page === 1 && (
        <>
          {/* First Name Input */}
          <C.InputContent focus={isFocus['first_name']} className="mt-0">
            <C.IconContent focus={isFocus['first_name']}>
              <FaUser />
            </C.IconContent>
            <C.InputBox>
              <C.InputLabel focus={isFocus['first_name']}>
                First Name
              </C.InputLabel>
              <Input
                type="text"
                name="first_name"
                value={first_name}
                required={true}
                onChange={(e) => onChange(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
              />
            </C.InputBox>
          </C.InputContent>

          {/* Last Name Input */}
          <C.InputContent focus={isFocus['last_name']} className="mt-1">
            <C.IconContent focus={isFocus['last_name']}>
              <FaUser />
            </C.IconContent>
            <C.InputBox>
              <C.InputLabel focus={isFocus['last_name']}>
                Last Name
              </C.InputLabel>
              <Input
                type="text"
                name="last_name"
                value={last_name}
                required={true}
                onChange={(e) => onChange(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
              />
            </C.InputBox>
          </C.InputContent>

          {/* Email Input */}
          <C.InputContent focus={isFocus['email']} className="mt-1">
            <C.IconContent focus={isFocus['email']}>
              <FaUser />
            </C.IconContent>
            <C.InputBox>
              <C.InputLabel focus={isFocus['email']}>Email</C.InputLabel>
              <Input
                type="email"
                name="email"
                value={email}
                required={true}
                onChange={(e) => onChange(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
              />
            </C.InputBox>
          </C.InputContent>

          {/* Company Input */}
          <C.InputContent focus={isFocus['company']} className="mt-1">
            <C.IconContent focus={isFocus['company']}>
              <FaUser />
            </C.IconContent>
            <C.InputBox>
              <C.InputLabel focus={isFocus['company']}>Company</C.InputLabel>
              <Input
                type="text"
                name="company"
                value={company}
                required={true}
                onChange={(e) => onChange(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
              />
            </C.InputBox>
          </C.InputContent>
          <C.IconPageContent>
            <FaCircle className="cursor-pointer" onClick={() => setPage(1)} />
            <FaRegCircle
              className="cursor-pointer"
              onClick={() => setPage(2)}
            />
          </C.IconPageContent>
          <C.Button onClick={() => setPage(2)}>Next Page</C.Button>
        </>
      )}

      {page === 2 && (
        <>
          {/* Sector Input */}
          <C.InputContent focus={isFocus['sector']} className="mt-1">
            <C.IconContent focus={isFocus['sector']}>
              <FaUser />
            </C.IconContent>
            <C.InputBox>
              <C.InputLabel focus={isFocus['sector']}>
                Sector (optional)
              </C.InputLabel>
              <Input
                type="text"
                name="sector"
                value={sector}
                required={false}
                onChange={(e) => onChange(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
              />
            </C.InputBox>
          </C.InputContent>

          {/* Job Position Input */}
          <C.InputContent focus={isFocus['job_position']} className="mt-1">
            <C.IconContent focus={isFocus['job_position']}>
              <FaUser />
            </C.IconContent>
            <C.InputBox>
              <C.InputLabel focus={isFocus['job_position']}>
                Job Position
              </C.InputLabel>
              <Input
                type="text"
                name="job_position"
                value={job_position}
                required={true}
                onChange={(e) => onChange(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
              />
            </C.InputBox>
          </C.InputContent>

          {/* Password Input */}
          <C.InputContent focus={isFocus['password']} className="mt-1">
            <C.IconContent focus={isFocus['password']}>
              <FaLock />
            </C.IconContent>
            <C.InputBox>
              <C.InputLabel focus={isFocus['password']}>Password</C.InputLabel>
              <Input
                type="password"
                name="password"
                value={password}
                required={true}
                onChange={(e) => onChange(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
              />
            </C.InputBox>
          </C.InputContent>

          {/* Re Password Input */}
          <C.InputContent focus={isFocus['re_password']} className="mt-1">
            <C.IconContent focus={isFocus['re_password']}>
              <FaLock />
            </C.IconContent>
            <C.InputBox>
              <C.InputLabel focus={isFocus['re_password']}>
                Confirm Password
              </C.InputLabel>
              <Input
                type="password"
                name="re_password"
                value={re_password}
                required={true}
                onChange={(e) => onChange(e)}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
              />
            </C.InputBox>
          </C.InputContent>
          <C.IconPageContent>
            <FaRegCircle
              className="cursor-pointer"
              onClick={() => setPage(1)}
            />
            <FaCircle className="cursor-pointer" onClick={() => setPage(2)} />
          </C.IconPageContent>
          <div className="flex gap-x-5">
            <C.Button onClick={() => setPage(1)}>Previous Page</C.Button>
            <C.Button type="submit">Register</C.Button>
          </div>
        </>
      )}

      <LabelContent>
        <LabelLink
          type="auth"
          size="small"
          onClick={() => setStatus({ message: '', type: '', loading: false })}
        >
          <Link to="/login">Already have an account?</Link>
        </LabelLink>
      </LabelContent>
    </C.Form>
  );
}
