import { useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import useUtils from '../../../hooks/useUtils.js';
import Loading from '../../../components/Loading/index.jsx';

export default function Activate() {
  const { verify, status, setStatus } = useAuth();
  const { setIsNavVisible } = useUtils();
  const navigate = useNavigate();

  const { uid, token } = useParams();

  useEffect(() => {
    const verifyAccount = () => {
      setStatus({ message: '', type: '', loading: true });
      verify(uid, token);
    };

    setIsNavVisible(false);
    verifyAccount();
  }, [setIsNavVisible, setStatus, token, uid, verify]);

  useEffect(() => {
    if (status.loading === false) {
      navigate('/login');
    }
  }, [status, navigate]);

  return <Loading />;
}
