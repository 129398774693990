import React, { useState } from 'react';
import {
  CardContainer,
  PageCard,
  CardTitle,
  Label,
  LabelItem,
  Input,
  FormButton,
} from '../../../styles/globals';
import * as C from '../styles';
import axios from 'axios';
import Slider from '@mui/material/Slider';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from 'react-toastify';
import { getError } from '../../../contexts/errors';
import { sortToPlot } from '../../../contexts/functions';
import useData from '../../../hooks/useData';
import useProperties from '../../../hooks/useProperties';

export default function InputData() {
  const [isLoadingVar, setIsLoadingVar] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState(sectionOptions[0]);
  const [variableOptions, setVariableOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedLeftVariable, setSelectedLeftVariable] = useState(
    variableOptions[0]
  );
  const [selectedRightVariable, setSelectedRightVariable] = useState(
    variableOptions[0]
  );
  const [itime, setItime] = useState(0.0);
  const [time, setTime] = useState([]);
  const [grid, setGrid] = useState([]);
  const unitTimeOptions = ['s', 'h'];
  const [selectedTimeUnit, setSelectedTimeUnit] = useState(unitTimeOptions[0]);
  const plotOptions = ['profile', 'trends', 'custom'];
  const [selectedPlot, setSelectedPlot] = useState(plotOptions[0]);
  const [tags, setTags] = useState();
  const {
    setLeftData,
    leftDataInTime,
    setLeftDataInTime,
    setRightData,
    rightDataInTime,
    setRightDataInTime,
    setLeftTitle,
    setRightTitle,
    leftUnit,
    rightUnit,
    setLeftUnit,
    setRightUnit,
    isLoading,
    setIsLoading,
    isPlotting,
    setIsPlotting,
    activePlotLabel,
    elevation,
    setElevation,
  } = useData();
  const { id, setID } = useProperties();

  const handleCaseID = (e) => {
    e.preventDefault();
    setIsLoading(1);
    setIsPlotting(0);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visualizer/retrieve-section-list/?id=` +
          id +
          `&type_plot=` +
          selectedPlot,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        setSectionOptions(res.data.section_list);
        setSelectedSection('Select a section pipe');
        setSelectedLeftVariable('Select a variable');
        setSelectedRightVariable('Select a variable');
        setLeftDataInTime([]);
        setRightDataInTime([]);
        setLeftData([]);
        setRightData([]);
        setIsLoading(2);
      })
      .catch((e) => {
        toast.error(getError(e));
        setIsLoading(0);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/visualizer/case-details/` + id, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
        },
      })
      .then((res) => {
        setTags(res.data.tags);
      })
      .catch((e) => {
        toast.error(getError(e));
      });
  };

  const handleChangePlot = (e) => {
    setSelectedPlot(e.target.value);
    setIsLoading(0);
    setIsPlotting(0);
    setIsReady(false);
  };

  const handleChangeSection = (e) => {
    setSelectedSection(e.target.value);
    setIsLoadingVar(true);
    setIsPlotting(0);
    const file_name =
      (selectedPlot === 'profile' || selectedPlot === 'custom'
        ? 'profiles'
        : 'trends') +
      '_' +
      e.target.value +
      '.csv';
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visualizer/retrieve-var-list/?id=` +
          id +
          `&type_plot=` +
          selectedPlot +
          `&file_name=` +
          file_name,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        setVariableOptions(res.data.var_list);
        setUnitOptions(res.data.unit_list);
        setIsLoadingVar(false);
      })
      .catch((e) => {
        toast.error(getError(e));
        setIsLoadingVar(false);
      });
  };

  const handleChangeLeftVariable = (e) => {
    setSelectedLeftVariable(e.target.value);
    setLeftTitle(e.target.value);
    setLeftUnit(
      unitOptions[
        variableOptions.findIndex((element) => element === e.target.value)
      ]
    );
    setIsPlotting(0);
    setIsReady(true);
    e.target.value === 'Select a variable' && //avoids both are 'Select a var'
      selectedRightVariable === 'Select a variable' &&
      setIsReady(false);
  };

  const handleChangeRightVariable = (e) => {
    setSelectedRightVariable(e.target.value);
    setRightTitle(e.target.value);
    setRightUnit(
      unitOptions[
        variableOptions.findIndex((element) => element === e.target.value)
      ]
    );
    setIsPlotting(0);
    setIsReady(true);
    e.target.value === 'Select a variable' && //avoids both are 'Select a var'
      selectedLeftVariable === 'Select a variable' &&
      setIsReady(false);
  };

  const handleChangeTimeUnit = (e) => {
    setSelectedTimeUnit(e.target.value);
    setItime(0);
  };

  const handleData = (e) => {
    if (activePlotLabel !== '') {
      e.preventDefault();
      setIsPlotting(1);
      const file_name =
        (selectedPlot === 'profile' || selectedPlot === 'custom'
          ? 'profiles'
          : 'trends') +
        '_' +
        selectedSection +
        '.csv';
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/visualizer/retrieve-dynamic-data/?id=` +
            id +
            `&type_plot=` +
            selectedPlot +
            `&file_name=` +
            file_name +
            `&left_variable=` +
            selectedLeftVariable +
            `&right_variable=` +
            selectedRightVariable,

          {
            headers: {
              Authorization: `JWT ${localStorage.getItem('access')}`,
            },
          }
        )
        .then((res) => {
          setGrid(res.data.grid_array);
          setLeftDataInTime(res.data.left_data_array_in_t);
          setRightDataInTime(res.data.right_data_array_in_t);
          setTime(res.data.time_array);
          setElevation(res.data.elevation_array);
          setIsPlotting(2);
          (selectedPlot === 'trends' || selectedPlot === 'custom') &&
            activePlotLabel !== '' &&
            saveTemporaryVars(
              null,
              res.data.left_data_array_in_t,
              res.data.right_data_array_in_t
            );
        })
        .catch((e) => {
          toast.error(getError(e));
          setIsPlotting(0);
        });
    } else {
      toast.warn('Please Add a plot');
    }
  };

  const handleChangeSlider = function (e, itime) {
    setLeftData(sortToPlot(grid, leftDataInTime[itime]));
    setRightData(sortToPlot(grid, rightDataInTime[itime]));
    setItime(itime);
    setIsPlotting(2);
    activePlotLabel !== '' &&
      saveTemporaryVars(
        time[itime],
        sortToPlot(grid, leftDataInTime[itime]),
        sortToPlot(grid, rightDataInTime[itime])
      );
  };

  const saveTemporaryVars = (actualTime, leftInputData, rightInputData) => {
    const tempData = {
      id: id,
      selectedPlot: selectedPlot,
      selectedTimeUnit: selectedTimeUnit,
      tags: tags === null ? {} : JSON.parse(tags.replaceAll("'", '"')),
      selectedSection: selectedSection,
      selectedLeftVariable: selectedLeftVariable,
      selectedRightVariable: selectedRightVariable,
      leftData: leftInputData,
      rightData: rightInputData,
      leftUnit: leftInputData.length === 0 ? '' : leftUnit,
      rightUnit: rightInputData.length === 0 ? '' : rightUnit,
      activePlotLabel: activePlotLabel,
      actualTime: actualTime,
      elevation: selectedPlot === 'profile' ? elevation : [],
    };
    localStorage.setItem(
      'tempData-' + activePlotLabel,
      JSON.stringify(tempData)
    );
  };

  return (
    <>
      <PageCard rowposition={1} rowsize={1} colsize={7} colposition={1}>
        <C.TopCardContent>
          <CardTitle>Setting up case</CardTitle>
        </C.TopCardContent>
        <CardContainer>
          <C.Form onSubmit={handleCaseID}>
            <C.FormGroup>
              <Label>Case id:</Label>
              <Input
                margin="none"
                typeformat="id"
                placeholder="Obtain Case id from the Catalogue"
                value={id}
                onChange={(e) => setID(e.target.value)}
              />
              <Label>Plot type: </Label>
              <C.Select onChange={handleChangePlot} value={selectedPlot}>
                {plotOptions.map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </C.Select>
            </C.FormGroup>
            <FormButton type="submit">
              {isLoading === 0
                ? 'Load ID'
                : isLoading === 1
                ? 'Loading...'
                : 'Loaded ID'}
            </FormButton>
          </C.Form>
        </CardContainer>
      </PageCard>

      <PageCard rowposition={2} rowsize={2} colsize={7} colposition={1}>
        {isLoadingVar ? (
          <BeatLoader color="gray" size={20} />
        ) : (
          <>
            <C.TopCardContent>
              <CardTitle>Select variables </CardTitle>
            </C.TopCardContent>
            <CardContainer>
              <C.CustomForm>
                <C.FormGroup>
                  <Label>Section: </Label>
                  <C.Select
                    onChange={handleChangeSection}
                    value={selectedSection}
                  >
                    <option>Select a section pipe</option>
                    {sectionOptions.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </C.Select>

                  <C.InputGroup align="row">
                    <Label>Time &nbsp;</Label>
                    <C.Select
                      onChange={handleChangeTimeUnit}
                      value={selectedTimeUnit}
                      mode="time"
                    >
                      {unitTimeOptions.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </C.Select>
                  </C.InputGroup>

                  <C.InputGroup align="col">
                    <Slider
                      min={0}
                      max={time.length - 1}
                      value={itime}
                      step={1}
                      //valueLabelDisplay="auto"
                      onChange={handleChangeSlider}
                      disabled={
                        selectedPlot === 'trends' || selectedPlot === 'custom'
                      }
                    />
                    <LabelItem>
                      {selectedTimeUnit === 'h' && time.length !== 0
                        ? ((1 / 3600) * time[itime])?.toFixed(1)
                        : time[itime]?.toFixed(1)}
                    </LabelItem>
                  </C.InputGroup>

                  <Label>Left variable: </Label>
                  <C.Select
                    onChange={handleChangeLeftVariable}
                    value={selectedLeftVariable}
                  >
                    <option>Select a variable</option>
                    {variableOptions.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </C.Select>

                  <Label>Right variable: </Label>
                  <C.Select
                    onChange={handleChangeRightVariable}
                    value={selectedRightVariable}
                    disabled={selectedPlot === 'custom'}
                  >
                    <option>Select a variable</option>
                    {variableOptions.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </C.Select>
                </C.FormGroup>
                <C.InputGroup className="gap-y-2" align="col">
                  <FormButton
                    color={isReady === true ? '' : 'unable'}
                    onClick={handleData}
                    disabled={isReady === false}
                  >
                    {isPlotting === 0
                      ? 'Plot'
                      : isPlotting === 1
                      ? 'Plotting...'
                      : 'Ready'}
                  </FormButton>
                </C.InputGroup>
              </C.CustomForm>
            </CardContainer>
          </>
        )}
      </PageCard>
    </>
  );
}
