import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import {
  Label,
  LabelItem,
  PageCard,
  CardTitle,
  CardContainer,
} from '../../../styles/globals';
import * as C from '../styles';
import PlotPipeRoutes from '../PlotPipeRoutes';
import useData from '../../../hooks/useData';
import Slider from '@mui/material/Slider';
import { sortToPlot } from '../../../contexts/functions';
import BeatLoader from 'react-spinners/BeatLoader';

ReactECharts.prototype.getEchartsInstance = function () {
  const _this = this;
  const e = _this.echarts; // Access the protected property.
  // Explicitly add width and height in opts.
  const opts = { width: '1200px', height: '600px', ..._this.props.opts };
  return (
    e.getInstanceByDom(_this.ele) || e.init(_this.ele, _this.props.theme, opts)
  );
};

export default function Plot() {
  const {
    elevation,
    selectedLeftVariable,
    gridPipeRoutes,
    setLeftData,
    leftData,
    leftDataInTime,
    timePipeRoutes,
    leftUnit,
    isPlottingRoutes,
    isPipeRoutesChecked,
  } = useData();
  const [itime, setItime] = useState(0.0);
  const unitTimeOptions = ['s', 'h'];
  const [selectedTimeUnit, setSelectedTimeUnit] = useState(unitTimeOptions[0]);

  const handleChangeSlider = function (e, itime) {
    setLeftData(sortToPlot(gridPipeRoutes, leftDataInTime[itime]));
    setItime(itime);
  };

  const handleChangeTimeUnit = (e) => {
    setSelectedTimeUnit(e.target.value);
    setItime(0);
  };

  return (
    <PageCard rowposition={4} rowsize={5} colsize={7} colposition={1}>
      <C.TopCardContent>
        <CardTitle>Plot</CardTitle>
      </C.TopCardContent>
      <CardContainer gap="small" direction="col">
        {isPipeRoutesChecked === 0 ? (
          <>No plot shown</>
        ) : (
          <>
            {leftDataInTime.length === 0 ? (
              isPlottingRoutes === 1 ? (
                <BeatLoader color="gray" size={20} />
              ) : (
                <>No plot show</>
              )
            ) : (
              <>
                <C.InputGroup align="row">
                  <Label>Time &nbsp;</Label>
                  <C.Select
                    onChange={handleChangeTimeUnit}
                    value={selectedTimeUnit}
                    mode="time"
                  >
                    {unitTimeOptions.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </C.Select>
                  <div className="px-8 w-96">
                    <Slider
                      min={0}
                      max={timePipeRoutes.length - 1}
                      value={itime}
                      step={1}
                      //valueLabelDisplay="auto"
                      onChange={handleChangeSlider}
                    />
                    <LabelItem>
                      {selectedTimeUnit === 'h' && timePipeRoutes.length !== 0
                        ? ((1 / 3600) * timePipeRoutes[itime])?.toFixed(1)
                        : timePipeRoutes[itime]?.toFixed(1)}
                    </LabelItem>
                  </div>
                </C.InputGroup>
                <PlotPipeRoutes
                  selectedLeftVariable={selectedLeftVariable}
                  leftUnit={leftUnit}
                  leftData={leftData}
                  elevation={elevation}
                />
              </>
            )}
          </>
        )}
      </CardContainer>
    </PageCard>
  );
}
