import tw from 'tailwind-styled-components';
import { NavLink } from 'react-router-dom';

export const HeaderTopbar = tw.div`
    flex
    items-center
    justify-between
    h-20
    font-extrabold
    bg-neutral-800
    text-zinc-200
    fixed
    w-[calc(100vw-3.5rem)]
    z-40
`;

export const HeaderTitle = tw(NavLink)`
    font-extrabold
    text-center
    flex
    items-center
    justify-center
    my-3
    text-6xl
    bg-clip-text
    bg-gradient-to-r
    from-emerald-600
    via-emerald-200
    to-emerald-600
    text-transparent
    bg-[length:200%]
    hover:bg-right
    duration-500
    cursor-pointer
`;

export const ProfileContainer = tw.div`
    flex
    items-center 
    justify-center 
    gap-x-5 
    h-full
    px-5 
    bg-emerald-800
    cursor-pointer
    text-zinc-200
    hover:bg-emerald-600
    duration-300
`;

export const AvatarPic = tw.img`
    h-16
`;

export const UsernameLabel = tw.h2`
    text-base
`;

export const DropdownProfile = tw.div`
    ${(props) => (props.$dropdown === true && 'right-0') || '-right-96'}

    text-zinc-200
    absolute
    top-full
    w-48
    transition-all
    ease-in-out
    duration-500
    rounded-lg
    mr-3
    mt-2
`;

export const DropdownLink = tw.a`

    ${(props) =>
      (props.position === 'first' && 'rounded-t-lg') ||
      (props.position === 'last' && 'rounded-b-lg') ||
      'rounded-none'}

    ${(props) =>
      (props.wip === 'true' && 'cursor-not-allowed') || 'cursor-pointer'}

    flex
    items-center
    no-underline 
    py-2
    mx-0
    gap-x-4
    px-10
    duration-300
    hover:gap-x-5
    bg-emerald-800
    hover:bg-emerald-600
`;

export const DropdownIcon = tw.div`
    bg-emerald-400
    text-emerald-900
    rounded-full 
    p-2
`;

export const TopbarOptions = tw.div`
    h-full 
    flex 
    items-center 
    gap-x-5
`;

export const NotificationsIcon = tw.div`

    ${(props) =>
      (props.wip === 'true' && 'cursor-not-allowed') || 'cursor-pointer'}

    bg-emerald-800 
    hover:bg-emerald-600 
    text-emerald-200 
    rounded-full 
    ${(props) => (props.progress === 'true' && 'p-4') || 'p-2'}
    duration-300
`;

export const ProgressIcon = tw.div`
    bg-emerald-800 
    text-emerald-200 
    rounded-lg
    p-4
    duration-300
    w-16
    rounded-full
`;
