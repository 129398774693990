import RoutesApp from './routes';
import GlobalStyle from './styles/globals';
import { AuthProvider } from './contexts/auth';
import { PropertiesProvider } from './contexts/properties';
import { UtilsProvider } from './contexts/utils';
import { DataProvider } from './contexts/data';

function App() {
  return (
    <AuthProvider>
      <UtilsProvider>
        <PropertiesProvider>
          <DataProvider>
            <RoutesApp />
            <GlobalStyle />
          </DataProvider>
        </PropertiesProvider>
      </UtilsProvider>
    </AuthProvider>
  );
}

export default App;
