import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { analysisParametersSchema } from '../../../schemas/analysisParametersSchema';
import axios from 'axios';
import { FormButton } from '../../../styles/globals';
import useProperties from '../../../hooks/useProperties';
import { toast } from 'react-toastify';
import { getError } from '../../../contexts/errors';
import { Label, Input } from '../../../styles/globals';
import * as C from '../styles';

export default function AnalysisParameters(props) {
  const { id, setProgressValue, setInProgress } = useProperties();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(analysisParametersSchema),
    defaultValues: {
      hydrateCriteria: '',
    },
  });

  useEffect(() => {
    const default_values = {
      hydrateCriteria: '30',
    };
    reset(default_values);
  }, [reset]);

  const onSubmit = (data) => {
    props.setOpen(false);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/visualizer/analyze-case-celery/` + id,
        {
          hydrate_criteria: data.hydrateCriteria / 100,
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        updateProgress(res.data.celery_task_id);
      })
      .catch((e) => {
        toast.error(getError(e));
      });
  };

  const updateProgress = (taskId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visualizer/celery-progress/` + taskId,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        setProgressValue(res.data.progress.percent);
        res.data.progress.percent < 100 && setInProgress(true);
        res.data.progress.percent < 100
          ? setTimeout(updateProgress, 3000, taskId)
          : res.data.result.length === 0
          ? toast.success(
              'Hyd formation analysis finished for id: ' +
                id +
                '. No section found.',
              {
                onClose: () => {
                  setInProgress(false);
                },
              }
            )
          : toast.warning(
              'Hyd formation analysis finished for id: ' +
                id +
                '. Possible sections found: ' +
                toToast(res.data.result),
              {
                onClose: () => {
                  setInProgress(false);
                },
                autoClose: false,
              }
            );
      })
      .catch((e) => {
        toast.error(getError(e));
      });
  };

  const toToast = (input) => {
    let output = [];
    try {
      input.map((i) => output.push(i[0]));
      return output.toString();
    } catch (error) {
      return '';
    }
  };

  return (
    <>
      <C.Form onSubmit={handleSubmit(onSubmit)}>
        <C.FormGroupTwo align="col">
          <C.InputGroup>
            <Label $fixedWidth>Hydrate criteria [%]:</Label>
            <Input
              $fullHeight
              typeformat="number"
              margin="small"
              {...register('hydrateCriteria', { required: true })}
              onChange={(event) => {
                const { value } = event.target;
                event.target.value = value;
              }}
            />
          </C.InputGroup>
          {errors?.hydrateCriteria?.message && (
            <C.InputError>{errors.hydrateCriteria?.message}</C.InputError>
          )}
          <FormButton type="submit">Run</FormButton>
        </C.FormGroupTwo>
      </C.Form>
    </>
  );
}
