import React, { useState, useEffect } from 'react';
import {
  PageCard,
  CardTitle,
  CardContainer,
  CustomTooltip,
} from '../../../styles/globals';
import * as C from '../styles';
import axios from 'axios';
import { TbCopy } from 'react-icons/tb';
import { BsClipboardData } from 'react-icons/bs';
import useProperties from '../../../hooks/useProperties';
import useData from '../../../hooks/useData';
import { REGIMES } from '../../../constants';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import AnalysisParameters from '../AnalysisParameters';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { getError } from '../../../contexts/errors';

export default function Cases() {
  const headerElements = [
    'field_name',
    'well_name',
    'regime',
    'case_name',
    'case_id',
    'size [MB]',
    'actions',
  ];
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { setID, setIDPipeRoutes, setImagePath } = useProperties();
  const {
    setIsLoading,
    setActivePlotList,
    setIsPipeRoutesChecked,
    setSectionDictPipeRoutes,
    setSelectedLeftVariable,
    setIsLoadingPipeRoutes,
  } = useData();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = (e, id) => {
    e.preventDefault();
    setID(id);
    setOpen(true);
  };
  const handleCopyBtn = (id) => {
    setID(id);
    setIDPipeRoutes(id);
    setIsPipeRoutesChecked(0);
    setSectionDictPipeRoutes([]);
    setSelectedLeftVariable('Select a variable');
    setImagePath(null);
    setIsLoadingPipeRoutes(0);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visualizer/analyze-case-events/?id=` +
          id,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access')}`,
          },
        }
      )
      .then((res) => {
        toast.warning(toToast(res.data.case_events));
      })
      .catch((e) => {
        toast.success(getError(e));
      });
  };

  const toToast = (input) => {
    let output = [];
    try {
      input.map(
        (i) =>
          (output +=
            'At ' +
            i[0].toFixed(2).toString() +
            (i[1] === 1 ? ' [s] begins restart' : ' [s] begins shut-in') +
            '. ')
      );
      return output + 'Case id successfully copied!.';
    } catch (error) {
      return '';
    }
  };

  const filteredData = data?.filter(
    (idata) =>
      idata.field_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      idata.case_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      idata.well_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      REGIMES[idata.regime].toLowerCase().includes(searchTerm.toLowerCase()) ||
      idata.case_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const firstCapital = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/visualizer/cases-catalogue/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('access')}`,
        },
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(0);
      })
      .catch((e) => console.log(e));
  }, [setActivePlotList, setIsLoading]);

  const handleCloseBtn = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <PageCard rowposition={1} rowsize={1} colsize={7} colposition={1}>
        <C.TopCardContent>
          <CardTitle>Cases available in database</CardTitle>
        </C.TopCardContent>
        <CardContainer gap="medium">
          <C.SearchBar
            type="text"
            placeholder="Search for keywords"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <C.Table>
            <C.TableHead>
              <C.TableRowHead>
                <C.TableHeadItem className="w-1/9">
                  {firstCapital(headerElements[0]).replace('_', ' ')}
                </C.TableHeadItem>
                <C.TableHeadItem className="w-1/9">
                  {firstCapital(headerElements[1]).replace('_', ' ')}
                </C.TableHeadItem>
                <C.TableHeadItem className="w-1/9">
                  {firstCapital(headerElements[2])}
                </C.TableHeadItem>
                <C.TableHeadItem className="w-1/4">
                  {firstCapital(headerElements[3]).replace('_', ' ')}
                </C.TableHeadItem>
                <C.TableHeadItem className="w-1/4">
                  {firstCapital(headerElements[4]).replace('_', ' ')}
                </C.TableHeadItem>
                <C.TableHeadItem className="w-1/12">
                  {firstCapital(headerElements[5])}
                </C.TableHeadItem>
                <C.TableHeadItem className="w-1/12">
                  {firstCapital(headerElements[6])}
                </C.TableHeadItem>
              </C.TableRowHead>
            </C.TableHead>
            <C.TableBody>
              {filteredData.length === 0 ? (
                <tr>
                  <td>No data found</td>
                </tr>
              ) : (
                filteredData?.map((icase, idx) => (
                  <C.TableRow key={idx}>
                    <C.TableItem className="w-1/9">
                      {icase['field_name']}
                    </C.TableItem>
                    <C.TableItem className="w-1/9">
                      {icase['well_name']}
                    </C.TableItem>
                    <C.TableItem className="w-1/9">
                      {REGIMES[icase['regime']]}
                    </C.TableItem>
                    <C.TableItem className="w-1/4">
                      {icase['case_name']}
                    </C.TableItem>
                    <C.TableItem className="w-1/4">
                      {icase['case_id']}
                    </C.TableItem>
                    <C.TableItem className="w-1/12">
                      {icase['size']}
                    </C.TableItem>
                    <C.TableItem className="w-1/12">
                      <CustomTooltip title="Select id" placement="top">
                        <C.Button
                          onClick={() => handleCopyBtn(icase['case_id'])}
                        >
                          <TbCopy />
                        </C.Button>
                      </CustomTooltip>
                      {/*                       <CustomTooltip title="Analyze case" placement="top">
                        <C.Button
                          onClick={(e) => handleOpenDialog(e, icase['case_id'])}
                        >
                          <BsClipboardData />
                        </C.Button>
                      </CustomTooltip>*/}
                    </C.TableItem>
                  </C.TableRow>
                ))
              )}
            </C.TableBody>
          </C.Table>
        </CardContainer>
        <Dialog open={open}>
          <DialogTitle>
            Analysis parameters
            <C.CloseButton onClick={(e) => handleCloseBtn(e)}>
              <AiOutlineClose />
            </C.CloseButton>
          </DialogTitle>
          <DialogContent>
            <AnalysisParameters setOpen={setOpen} />
          </DialogContent>
        </Dialog>
      </PageCard>
    </>
  );
}
