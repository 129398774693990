import tw from 'tailwind-styled-components';
import styled from 'styled-components';

export const Input = tw.input`
    absolute 
    w-full 
    h-full 
    text-xl
    text-zinc-200
    px-3
    py-2 
    border-none
    outline-none
    left-0 
    top-0
    bg-neutral-900
    autofill:transition
    autofill:duration-[600000s]
    autofill:delay-0
`;

export const Number = styled.input`
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  -webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
`;

export const NumberInput = tw(Number)`

    ${(props) =>
      (props.margin === 'big' && 'mx-8') ||
      (props.margin === 'small' && 'mx-2') ||
      'mx-4'}

    outline-none
    mb-2
    py-2
    text-center
    w-20
    rounded
    bg-gray-100
    border-2
    border-indigo-200
`;
