import tw from 'tailwind-styled-components'
import {NavLink} from 'react-router-dom';

export const NavListTitle = tw.span`
    px-2.5
    my-3
    text-zinc-200
    font-bold
    text-sm
    uppercase
`

export const NavSidebar = tw.div`

    ${(props) => (
        (props.$navvisible === false ? 'w-14' : `w-64`)   
    )}

    bg-neutral-800
    h-full 
    fixed
    z-50
    top-0
    left-0
    overflow-x-hidden
    transition-all
    duration-200
    ease-[cubic-bezier(0.25, 0.1, 0.25, 1.0)]
    flex
    flex-col
`

export const NavContainer = tw.div`
    mb-3
    container
    flex
    flex-wrap
    justify-center
    items-center
    mx-auto
`

export const NavList = tw.ul`

    ${(props) => (
        (props.$navvisible === false && 'pointer-events-none') ||
        'pointer-events-auto'
    )}

    pt-2
`

export const NavMenuLink = tw(NavLink)`

    ${(props) => (
        (props.submenuitem === 'true' && 'px-6') ||
        'px-3'
    )}

    ${(props) => (
        (props.hasactive === 'true' && 'text-emerald-300') ||
        'text-zinc-400'
    )}

    flex
    items-center 
    my-3
    gap-x-3
    cursor-pointer
    hover:text-emerald-300
    transition
    duration-300
    grow
    whitespace-nowrap
`

export const NavHeader = tw.li`
    flex
    items-center
    justify-center
    h-20
    text-2xl
    gap-x-8
    font-extrabold
    tracking-wider
    bg-gradient-to-r
    from-emerald-900
    via-emerald-700
    to-emerald-900
    cursor-pointer
    text-zinc-200
    bg-[length:200%]
    hover:bg-right
    duration-500
`

export const LogoImage = tw.img`

    ${(props) => (
        (props.$navvisible === false ? 'hidden' : 'block')
    )}

    h-12
    cursor-pointer
    hover:brightness-125
    transition-all
    duration-300
`

export const NavFooter = tw.li`
    ${(props) => (
        (props.$navvisible === false ? 'hidden' : 'flex')
    )}

    flex-col
    justify-center
    items-center
    last:mt-auto
`

export const NavController = tw.div`
    
    ${(props) => (
        (props.controller === 'show' ? 'left-56' : 'left-3 rotate-180')
    )}

    flex 
    items-center 
    justify-center 
    cursor-pointer 
    rounded-full 
    bg-gray-800 
    w-12
    h-12
    top-3
    transition-all
    duration-500
    hover:bg-gray-700
    z-20
    fixed
`

export const Symbol = tw.span`


    ${(props) => (
        (props.submenuitemvisible === 'true' && 'rotate-180') ||
        ''
    )}

    ${(props) => (
        (props.$navvisible === false ? 'hidden' : 'block') ||
        ''
    )}

    ${(props) => (
        (props.$headernavvisible === true && 'rotate-180') ||
        ''
    )}

    text-3xl
    transition-transform
    duration-200
`

export const LinkText = tw.span`
    ${(props) => (
        (props.$navvisible === false ? 'hidden' : 'block')
    )}

    text-lg 
    font-medium 
    grow
`

export const HeaderLinkText = tw.span`
    ${(props) => (
        (props.$navvisible === false ? 'hidden' : 'block')
    )}

    text-4xl 
    uppercase
    text-zinc-200
    whitespace-nowrap
`
export const LogoutButton = tw.button`

    bg-neutral-700
    hover:bg-neutral-600
    text-zinc-200
    py-2
    ease-linear
    duration-300
    cursor-pointer
    w-full
    flex
    items-center
`

export const NavToggle = tw.div`

    ${(props) => (
        (props.$quickmode === true && 'bg-zinc-600') ||
        'bg-emerald-600'
    )}

    flex
    w-20
    h-10
    my-5
    rounded-full
    cursor-pointer
    duration-300
`

export const NavSwitcher = tw.span`

    ${(props) => (
        (props.$quickmode === true && 'ml-0') ||
        'ml-10'
    )}

    h-10
    w-10
    bg-zinc-200
    rounded-full
    duration-300
    text-zinc-600
    flex
    items-center
    justify-center
`

export const FooterLabel = tw.span`
    text-white
    text-lg
`
