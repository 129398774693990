import * as C from '../styles';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import useUtils from '../../../hooks/useUtils.js';
import Input from '../../../components/AuthInput';
import { FaLock } from 'react-icons/fa';
import HaiLogo from '../../../assets/Logo_HAI.svg';
import { Label } from '../../../styles/globals.js';

export default function ResetPasswordConfirm() {
  const { resetPasswordConfirm, status, setStatus } = useAuth();
  const { setIsNavVisible } = useUtils();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    new_password: '',
    re_new_password: '',
  });

  const { new_password, re_new_password } = formData;

  const { uid, token } = useParams();

  const [isFocus, setIsFocus] = useState({ email: 'no' });

  const handleFocus = (e) => {
    setIsFocus({ ...isFocus, [e.target.name]: 'yes' });
  };

  const handleBlur = (e) => {
    if (e.target.value === '')
      setIsFocus({ ...isFocus, [e.target.name]: 'no' });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    setStatus({ ...status, loading: true });
    resetPasswordConfirm(uid, token, new_password, re_new_password);
  };

  useEffect(() => {
    setIsNavVisible(false);
  }, [setIsNavVisible, setStatus]);

  useEffect(() => {
    if (status.type === 'success') {
      navigate('/');
    }
  }, [status, navigate]);

  return (
    <C.Form onSubmit={(e) => onSubmit(e)}>
      <a href="https://hybridai.com.br/" target="_blank" rel="noreferrer">
        <C.LoginImage src={HaiLogo} />
      </a>
      <C.LoginTitle>Reset Password</C.LoginTitle>
      <Label type={status.type}>{status.message}</Label>

      {/* New Password Input */}
      <C.InputContent focus={isFocus['new_password']} className="mt-1">
        <C.IconContent focus={isFocus['new_password']}>
          <FaLock />
        </C.IconContent>
        <C.InputBox>
          <C.InputLabel focus={isFocus['new_password']}>Password</C.InputLabel>
          <Input
            type="password"
            name="new_password"
            value={new_password}
            required={true}
            onChange={(e) => onChange(e)}
            onFocus={(e) => handleFocus(e)}
            onBlur={(e) => handleBlur(e)}
          />
        </C.InputBox>
      </C.InputContent>

      {/* Re New Password Input */}
      <C.InputContent focus={isFocus['re_new_password']} className="mt-1">
        <C.IconContent focus={isFocus['re_new_password']}>
          <FaLock />
        </C.IconContent>
        <C.InputBox>
          <C.InputLabel focus={isFocus['re_new_password']}>
            Confirm Password
          </C.InputLabel>
          <Input
            type="password"
            name="re_new_password"
            value={re_new_password}
            required={true}
            onChange={(e) => onChange(e)}
            onFocus={(e) => handleFocus(e)}
            onBlur={(e) => handleBlur(e)}
          />
        </C.InputBox>
      </C.InputContent>

      <C.Button type="submit">Change Password</C.Button>
    </C.Form>
  );
}
