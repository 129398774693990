import { createContext, useState } from 'react';
export const PropertiesContext = createContext({});
export const PropertiesProvider = ({ children }) => {
  const [id, setID] = useState('');
  const [idPipeRoutes, setIDPipeRoutes] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [progressValue, setProgressValue] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  return (
    <PropertiesContext.Provider
      value={{
        id,
        setID,
        idPipeRoutes,
        setIDPipeRoutes,
        imagePath,
        setImagePath,
        progressValue,
        setProgressValue,
        inProgress,
        setInProgress,
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};
