import { Label } from '../../../styles/globals';
import useData from '../../../hooks/useData';
import * as C from '../styles';
import { CustomTooltip } from '../../../styles/globals';
import { FaFileExport } from 'react-icons/fa';
import FileSaver from 'file-saver';

export default function PlotCard({ data }) {
  const { activePlotLabel } = useData();

  const fromJsonProfileToCsv = () => {
    const inputData = JSON.parse(
      localStorage.getItem('tempData-' + activePlotLabel)
    );
    const dummy = '';
    const outputData = [];
    if (inputData.selectedLeftVariable === 'Select a variable') {
      outputData[0] = `length[m];${inputData.selectedRightVariable}${inputData.rightUnit}; elevationProfile[m] \n`;
      for (let index = 0; index < inputData.elevation.length; index++) {
        outputData[index + 1] = dummy
          .concat(inputData.elevation[index][0])
          .concat(';')
          .concat(inputData.rightData[index][1])
          .concat(';')
          .concat(inputData.elevation[index][1])
          .concat('\n');
      }
    } else if (inputData.selectedRightVariable === 'Select a variable') {
      outputData[0] = `length[m];${inputData.selectedLeftVariable}${inputData.leftUnit}; elevationProfile[m] \n`;
      for (let index = 0; index < inputData.elevation.length; index++) {
        outputData[index + 1] = dummy
          .concat(inputData.elevation[index][0])
          .concat(';')
          .concat(inputData.leftData[index][1])
          .concat(';')
          .concat(inputData.elevation[index][1])
          .concat('\n');
      }
    } else {
      outputData[0] = `length[m];${inputData.selectedLeftVariable} ${inputData.leftUnit};${inputData.selectedRightVariable}${inputData.rightUnit}; elevationProfile[m] \n`;
      for (let index = 0; index < inputData.elevation.length; index++) {
        outputData[index + 1] = dummy
          .concat(inputData.elevation[index][0])
          .concat(';')
          .concat(inputData.leftData[index][1])
          .concat(';')
          .concat(inputData.rightData[index][1])
          .concat(';')
          .concat(inputData.elevation[index][1])
          .concat('\n');
      }
    }
    return outputData.join('');
  };

  const fromJsonTrendsCustomToCsv = () => {
    const inputData = JSON.parse(
      localStorage.getItem('tempData-' + activePlotLabel)
    );
    const dummy = '';
    const outputData = [];
    if (inputData.selectedLeftVariable === 'Select a variable') {
      outputData[0] = `time[s];${inputData.selectedRightVariable}${inputData.rightUnit} \n`;
      for (let index = 0; index < inputData.rightData.length; index++) {
        outputData[index + 1] = dummy
          .concat(inputData.rightData[index][0])
          .concat(';')
          .concat(inputData.rightData[index][1])
          .concat('\n');
      }
    } else if (inputData.selectedRightVariable === 'Select a variable') {
      outputData[0] = `time[s];${inputData.selectedLeftVariable}${inputData.leftUnit} \n`;
      for (let index = 0; index < inputData.leftData.length; index++) {
        outputData[index + 1] = dummy
          .concat(inputData.leftData[index][0])
          .concat(';')
          .concat(inputData.leftData[index][1])
          .concat('\n');
      }
    } else {
      outputData[0] = `time[s];${inputData.selectedLeftVariable} ${inputData.leftUnit};${inputData.selectedRightVariable}${inputData.rightUnit} \n`;
      for (let index = 0; index < inputData.leftData.length; index++) {
        outputData[index + 1] = dummy
          .concat(inputData.leftData[index][0])
          .concat(';')
          .concat(inputData.leftData[index][1])
          .concat(';')
          .concat(inputData.rightData[index][1])
          .concat('\n');
      }
    }
    return outputData.join('');
  };

  const handleExportBtn = () => {
    const blob = new Blob(
      [
        data?.selectedPlot === 'profile'
          ? fromJsonProfileToCsv()
          : fromJsonTrendsCustomToCsv(),
      ],
      {
        type: 'text/plain;charset=utf-8', //'application/json;charset=utf-8',
      }
    );
    FileSaver.saveAs(blob, 'variable.csv');
  };

  return (
    <>
      <div className="flex flex-col items-start">
        <Label>id: </Label>
        <p>{data?.id}</p>
        <Label>Plot type: </Label>
        <p>{data?.selectedPlot}</p>
        <Label>Section: </Label>
        <p>{data?.selectedSection}</p>
        <Label>Left variable: </Label>
        <p>
          {data?.selectedLeftVariable === 'Select a variable'
            ? 'None'
            : data?.selectedLeftVariable}
        </p>
        <Label>Right variable: </Label>
        <p>
          {data?.selectedRightVariable === 'Select a variable'
            ? 'None'
            : data?.selectedRightVariable}
        </p>
        <Label>Time:</Label>
        <p>
          {data?.actualTime
            ? data?.selectedTimeUnit === 'h'
              ? ((1 / 3600) * data?.actualTime).toFixed(1)
              : data?.actualTime
            : 'None'}
        </p>
        <CustomTooltip title="Export csv" placement="top">
          <C.Button className="h-12" onClick={() => handleExportBtn()}>
            <FaFileExport />
          </C.Button>
        </CustomTooltip>
      </div>
    </>
  );
}
